import { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiOfficeBuilding } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { PostEmpresa } from "../../models/EmpresaModel";
import { ApiResponseType } from "../../entities/ApiResponseEntity";

import Layout from "../../components/Layout";

const toastTitle = "Empresa";

export default function CadastroEmpresaFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formNome, setFormNome] = useState("");
	const [formCNPJ, setFormCNPJ] = useState("");
	const [formToken, setFormToken] = useState("");

	const { isLoading, isFetching, isRefetching, refetch } = useQuery(["cadastros", "empresas", id], () => fetchData(id), { enabled: id !== undefined });
	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetEmpresa(id);
		if (resp.Result === 1 && resp.Data) {
			setFormNome(resp.Data.nome);
			setFormCNPJ(resp.Data.cnpj);
			setFormToken(resp.Data.token);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	async function mutateData(data: PostEmpresa) {
		return await apiService.PostEmpresa(data);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["cadastros", "empresas", id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/cadastroEmpresaFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/cadastrosEmpresas");
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: PostEmpresa = {
			id: Number(id) ?? null,
			nome: formNome,
			cnpj: formCNPJ,
			token: formToken,
		};

		mutation.mutate(data);
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/cadastrosEmpresas"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiOfficeBuilding} size={1} className="me-1" />
					Formulário de Empresa
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				{id && (
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						<Button
							type="button"
							variant="air-blue"
							className="text-white"
							onClick={() => {
								navigate("/configuracoesEmpresaFormulario/" + id + "/" + formNome);
							}}
						>
							Configurações
						</Button>
					</div>
				)}
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="cnpj">
							<Form.Label>CNPJ</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o CNPJ"
								value={formCNPJ}
								onChange={(event) => {
									setFormCNPJ(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="token">
							<Form.Label>Token</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o token"
								value={formToken}
								onChange={(event) => {
									setFormToken(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>

						{!formStatus ? (
							<Button
								className="me-2"
								variant="dark"
								type="button"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						) : (
							<>
								<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								{id && (
									<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
										Cancelar
									</Button>
								)}
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</Layout>
	);
}

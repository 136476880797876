import { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import { IMaskInput } from "react-imask";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiAccountMultipleOutline, mdiChevronLeft } from "@mdi/js";

import { CNPJMask, CelularMask, TelefoneMask } from "../../config/defines";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { CadastroCliente } from "../../models/ClienteModel";

import Layout from "../../components/Layout";
import { ResumoClienteModel } from "../../models/ResumoClienteModel";
import { SelectStyle } from "../../config/select";

const toastTitle = "Cliente";

export default function CadastroClienteFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { handleCliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formEmpresa, setFormEmpresa] = useState<{ label: string; value: number } | undefined>();
	const [formNome, setFormNome] = useState<string>();
	const [formCNPJ, setFormCNPJ] = useState<string>();
	const [formContato, setFormContato] = useState<string>();
	const [formEmail, setFormEmail] = useState<string>();
	const [formTelefone, setFormTelefone] = useState<string>();
	const [formCelular, setFormCelular] = useState<string>();
	const [formSelecionando, setFormSelecionando] = useState(false);

	const queryEmpresas = useQuery(["cadastros", "empresas"], () => fetchDataEmpresas());
	const { data, isLoading, isFetching, isRefetching, refetch } = useQuery(["cadastros", "clientes", id], () => fetchData(id), { enabled: id !== undefined });

	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	useEffect(() => {
		if (data && data.Data?.empresaId && queryEmpresas.data) {
			let empresa = queryEmpresas.data.Data?.find((item) => {
				return item.id === data.Data?.empresaId;
			});
			if (empresa) {
				setFormEmpresa({ label: empresa.nome, value: empresa.id });
			}
		}
	}, [data, queryEmpresas.data]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetCadastroCliente(id);
		if (resp.Result === 1 && resp.Data) {
			setFormNome(resp.Data.nome);
			setFormCNPJ(resp.Data.cnpj);
			setFormContato(resp.Data.contato);
			setFormEmail(resp.Data.email);
			setFormTelefone(resp.Data.telefone);
			setFormCelular(resp.Data.celular);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return resp;
	}

	async function fetchDataEmpresas() {
		return await apiService.GetEmpresas();
	}

	async function mutateData(data: CadastroCliente) {
		return await apiService.PostCliente(data);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["cadastros", "clientes", id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/cadastroClienteFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/cadastrosClientes");
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: CadastroCliente = {
			id: Number(id) ?? null,
			nome: formNome,
			cnpj: formCNPJ,
			contato: formContato,
			email: formEmail,
			telefone: formTelefone,
			celular: formCelular,
			empresaId: formEmpresa?.value,
		};

		mutation.mutate(data);
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function handleSelecionar() {
		setFormSelecionando(true);
		let resp = await apiService.getResumo(Number(id));
		if (resp.Result === 1 && resp.Data) {
			let cliente: ResumoClienteModel = {
				id: Number(id),
				nome: formNome!,
				distanciaVoo: resp.Data[0] ? resp.Data[0].distanciaVoo : "-",
				numeroVoos: resp.Data[0] ? resp.Data[0].numeroVoos : "-",
				tempoVoo: resp.Data[0] ? resp.Data[0].tempoVoo : "-",
			};
			handleCliente(cliente);
			navigate("/dashboard");
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		setFormSelecionando(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/cadastrosClientes"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiAccountMultipleOutline} size={1} className="me-1" />
					Formulário de Cliente
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				{id && (
					<div className="d-flex gap-2 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
						<Button type="button" variant="air-blue" className="text-white" onClick={handleSelecionar} disabled={formSelecionando}>
							Selecionar
						</Button>
						<Button
							type="button"
							variant="air-blue"
							className="text-white"
							onClick={() => {
								navigate("/configuracoesFormulario/" + id + "/" + formNome);
							}}
						>
							Configurações
						</Button>
					</div>
				)}
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="empresa">
							<Form.Label>Empresa</Form.Label>
							<Select
								placeholder={"Selecione"}
								className="fs-6 bg-white rounded"
								value={formEmpresa}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={queryEmpresas.data?.Data?.map((drone: any) => {
									return { label: drone.nome, value: drone.id };
								})}
								onChange={(value) => {
									if (value) setFormEmpresa(value);
								}}
								isDisabled={!formStatus || !!id}
								styles={SelectStyle}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="cnpj">
							<Form.Label>CNPJ</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={CNPJMask}
								type="text"
								placeholder="Informe aqui o CNPJ"
								value={formCNPJ}
								onChange={(event) => {
									setFormCNPJ(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="contato">
							<Form.Label>Contato</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o contato"
								value={formContato}
								onChange={(event) => {
									setFormContato(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="email">
							<Form.Label>E-mail</Form.Label>
							<Form.Control
								type="email"
								placeholder="Informe aqui o e-mail"
								value={formEmail}
								onChange={(event) => {
									setFormEmail(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="telefone">
							<Form.Label>Telefone</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={TelefoneMask}
								type="text"
								placeholder="Informe aqui o telefone"
								value={formTelefone}
								onChange={(event) => {
									setFormTelefone(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="celular">
							<Form.Label>Celular</Form.Label>
							<Form.Control
								as={IMaskInput}
								mask={CelularMask}
								type="text"
								placeholder="Informe aqui o celular"
								value={formCelular}
								onChange={(event) => {
									setFormCelular(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>

						{!formStatus ? (
							<Button
								className="me-2"
								variant="dark"
								type="button"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						) : (
							<>
								<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								{id && (
									<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
										Cancelar
									</Button>
								)}
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</Layout>
	);
}

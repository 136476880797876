
const DEGTORAD = Math.PI/180;

export class Vector3 {
    constructor(public x: number, public y: number, public z: number) {

    }

    distanceOf(other: Vector3) {
        return Math.sqrt((this.x - other.x) ** 2 + (this.y - other.y) ** 2 + (this.z - other.z) ** 2);
    }

    static FromPolar(latitude: number, longitude: number, altitude: number) {
        latitude = latitude * DEGTORAD;
        longitude = longitude * DEGTORAD;
        var x = altitude * Math.cos(latitude) * Math.sin(longitude);
        var y = altitude * Math.sin(latitude);
        var z = altitude * Math.cos(latitude) * Math.cos(longitude);
        return new Vector3(x, y, z);
    }

}


import { useEffect, useState } from "react";
import { Button, Card, Form, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiBatteryHigh, mdiChevronLeft } from "@mdi/js";

import { SelectStyle } from "../../config/select";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";

import Layout from "../../components/Layout";
import { PostBateria } from "../../models/BateriaModel";

const toastTitle = "Bateria";

export default function BateriaFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { user, cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const clienteAtual = user!.getCliente(cliente!.id);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);

	const [formNumeroSerie, setFormNumeroSerie] = useState<string>();
	const [formModelo, setFormModelo] = useState<string>();
	const [formCodigo, setFormCodigo] = useState<string>();
	const [formSituacao, setFormSituacao] = useState<{ label: string; value: number } | undefined>();
	const [formCliente, setFormCliente] = useState<{ label: string; value: number } | undefined>();
	const [formDrone, setFormDrone] = useState<{ label: string; value: number } | undefined>();
	const [formDataCompra, setFormDataCompra] = useState<Moment>();
	const [formObservacao, setFormObservacao] = useState<string>("");
	const [formCapacidadeTeorica, setFormCapacidadeTeorica] = useState<number>();
	const [formNumeroCelulas, setFormNumeroCelulas] = useState<number>();
	const [formCiclosCarga, setFormCiclosCarga] = useState<number>();
	const [formNumeroVoos, setFormNumeroVoos] = useState<number>();
	const [formTempoVoo, setFormTempoVoo] = useState<number>();
	const [formDataUltimoVoo, setFormDataUltimoVoo] = useState<Moment>();

	const querySituacoes = useQuery(["bateria", "situacoes"], () => fetchDataSituacoes());
	const queryDrones = useQuery(["drones", cliente!.id], () => fetchDataDrones(cliente!.id));
	const { isLoading, isFetching, isRefetching, refetch } = useQuery(["bateria", id], () => fetchData(id), { enabled: id !== undefined });

	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetBateria(id);
		if (resp.Result === 1 && resp.Data) {
			setFormNumeroSerie(resp.Data.numeroSerie);
			setFormModelo(resp.Data.modelo);
			setFormCodigo(resp.Data.codigo);
			setFormSituacao(resp.Data.situacao ? { label: resp.Data.situacao?.nome, value: resp.Data.situacao?.id } : undefined);
			setFormCliente(resp.Data.cliente ? { label: resp.Data.cliente?.nome, value: resp.Data.cliente?.id } : undefined);
			setFormDrone(resp.Data.drone ? { label: resp.Data.drone?.nome, value: resp.Data.drone?.id } : undefined);
			setFormDataCompra(moment(resp.Data.dataCompra.ticks));
			setFormObservacao(resp.Data.observacao);
			setFormCapacidadeTeorica(resp.Data.capacidadeTeorica ?? undefined);
			setFormNumeroCelulas(resp.Data.numeroCelulas ?? undefined);
			setFormCiclosCarga(resp.Data.estatisticas.ciclosCarga ?? undefined);
			setFormNumeroVoos(resp.Data.estatisticas.numeroVoos ?? undefined);
			setFormTempoVoo(resp.Data.estatisticas.tempoVoo ?? undefined);
			setFormDataUltimoVoo(moment(resp.Data.estatisticas.dataUltimoVoo.ticks));
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	async function fetchDataSituacoes() {
		return await apiService.GetSituacoesBateria();
	}

	async function fetchDataDrones(clienteId: any) {
		return await apiService.GetDronesCliente(clienteId);
	}

	async function mutateData(data: PostBateria) {
		return await apiService.postBateria(data);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["drone", id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/bateriaFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/baterias");
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: PostBateria = {
			id: Number(id) ?? null,
			numeroSerie: formNumeroSerie!,
			modelo: formModelo!,
			codigo: formCodigo!,
			capacidadeTeorica: formCapacidadeTeorica ?? null,
			numeroCelulas: formNumeroCelulas ?? null,
			observacao: formObservacao,
			dataCompraTicks: formDataCompra?.valueOf() ?? null,
			situacaoId: formSituacao?.value ?? null,
			clienteId: formCliente?.value ?? null,
			droneId: formDrone?.value ?? null,
			ciclosCarga: formCiclosCarga ?? null,
			dataUltimoVooTicks: formDataUltimoVoo?.valueOf() ?? null,
			tempoVoo: formTempoVoo ?? null,
			numeroVoos: formNumeroVoos ?? null,
		};

		mutation.mutate(data);
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/baterias"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiBatteryHigh} size={1} className="me-1" />
					Formulário de Bateria
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="numeroSerie">
							<Form.Label>Número de Série</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o número de série"
								value={formNumeroSerie}
								onChange={(event) => {
									setFormNumeroSerie(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="modelo">
							<Form.Label>Modelo</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o modelo"
								value={formModelo}
								onChange={(event) => {
									setFormModelo(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="codigo">
							<Form.Label>Código</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o código"
								value={formCodigo}
								onChange={(event) => {
									setFormCodigo(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="serial">
							<Form.Label>Situação</Form.Label>
							<Select
								placeholder={"Selecione"}
								className="fs-6 bg-white rounded"
								value={formSituacao}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={querySituacoes.data?.Data?.map((situacao: any) => {
									return { label: situacao.nome, value: situacao.id };
								})}
								onChange={(value) => {
									if (value) setFormSituacao(value);
								}}
								isDisabled={!formStatus}
								styles={SelectStyle}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Cliente</Form.Label>
							<Select
								placeholder={"Selecione"}
								className="fs-6 bg-white rounded"
								value={formCliente}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={user!.cliente
									.filter((x: any) => x.empresaId === clienteAtual!.empresaId)
									.map((cliente: any) => {
										return { label: cliente.nome, value: cliente.id };
									})}
								onChange={(value) => {
									if (value) setFormCliente(value);
								}}
								isDisabled={!formStatus}
								styles={SelectStyle}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Drone</Form.Label>
							<Select
								placeholder={"Selecione"}
								className="fs-6 bg-white rounded"
								value={formDrone}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={queryDrones.data?.Data?.map((drone: any) => {
									return { label: drone.nome, value: drone.id };
								})}
								onChange={(value) => {
									if (value) setFormDrone(value);
								}}
								isDisabled={!formStatus}
								styles={SelectStyle}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="data">
							<Form.Label>Data Fabricação</Form.Label>
							<ReactDatePicker
								placeholderText="Data Fabricação"
								className="form-control"
								calendarClassName="shadow-lg"
								onChange={(data) => {
									setFormDataCompra(data ? moment(data) : undefined);
								}}
								selected={formDataCompra?.toDate()}
								dateFormat="dd/MM/yyyy"
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="observacao">
							<Form.Label>Observação</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui a observação"
								value={formObservacao}
								onChange={(event) => {
									setFormObservacao(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="capacidadeTeorica">
							<Form.Label>Capacidade Teórica (mAh)</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui a capacidade teórica (mAh)"
								value={formCapacidadeTeorica}
								onChange={(event) => {
									setFormCapacidadeTeorica(event.target.value ? Number(event.target.value) : undefined);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="numeroCelulas">
							<Form.Label>Número de Células</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o número de células"
								value={formNumeroCelulas}
								onChange={(event) => {
									setFormNumeroCelulas(event.target.value ? Number(event.target.value) : undefined);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="ciclosCarga">
							<Form.Label>Ciclos de Carga</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o ciclos de carga"
								value={formCiclosCarga}
								onChange={(event) => {
									setFormCiclosCarga(event.target.value ? Number(event.target.value) : undefined);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="numeroVoos">
							<Form.Label>Número de Vôo(s)</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o número de vôo(s)"
								value={formNumeroVoos}
								onChange={(event) => {
									setFormNumeroVoos(event.target.value ? Number(event.target.value) : undefined);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="tempoVoo">
							<Form.Label>Tempo de Vôo(s)</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o tempo de vôo(s)"
								value={formTempoVoo}
								onChange={(event) => {
									setFormTempoVoo(event.target.value ? Number(event.target.value) : undefined);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="dataUltimoVoo">
							<Form.Label>Data Último Voo</Form.Label>
							<ReactDatePicker
								placeholderText="Data Último Voo"
								className="form-control"
								calendarClassName="shadow-lg"
								onChange={(data) => {
									setFormDataUltimoVoo(data ? moment(data) : undefined);
								}}
								selected={formDataUltimoVoo?.toDate()}
								dateFormat="dd/MM/yyyy"
								disabled={!formStatus}
							/>
						</Form.Group>

						{!formStatus ? (
							<Button
								className="me-2"
								variant="dark"
								type="button"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						) : (
							<>
								<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								{id && (
									<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
										Cancelar
									</Button>
								)}
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</Layout>
	);
}

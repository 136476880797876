export {};

declare global {
	export interface String {
		toCapitalize(): this;
		toCapitalizeAll(): this;
	}
}

if (!String.prototype.toCapitalize) {
	// eslint-disable-next-line
	String.prototype.toCapitalize = function () {
		return this.charAt(0).toUpperCase() + this.slice(1);
	};
}

if (!String.prototype.toCapitalizeAll) {
	// eslint-disable-next-line
	String.prototype.toCapitalizeAll = function () {
		return this.split(" ")
			.map((i) => {
				return i.toCapitalize();
			})
			.join(" ");
	};
}

import { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import GoogleMap, { Map } from "google-maps-react-markers";
import { geocode, OutputFormat, RequestType } from "react-geocode";
import Icon from "@mdi/react";
import { mdiAlphaHCircleOutline, mdiMapSearch } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";

import Layout from "../../components/Layout";
import Marker from "../../components/Marker";
import { CoordinatesModel } from "../../models/CoordinatesModel";

const toastTitle = "Homepoint SOS";

export default function HomepointSOSFormulario() {
	const navigate = useNavigate();
	const { handleToast } = useToast();
	const { cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(false);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formEndereco, setFormEndereco] = useState("");
	const [formEnderecoLoading, setFormEnderecoLoading] = useState(false);
	const [formBaseLatitudeInicial, setFormBaseLatitudeInicial] = useState<number>();
	const [formBaseLongitudeInicial, setFormBaseLongitudeInicial] = useState<number>();
	const [formBaseLatitude, setFormBaseLatitude] = useState<number>();
	const [formBaseLongitude, setFormBaseLongitude] = useState<number>();
	const [formSOSLatitudeInicial, setFormSOSLatitudeInicial] = useState<number>();
	const [formSOSLongitudeInicial, setFormSOSLongitudeInicial] = useState<number>();
	const [formSOSLatitude, setFormSOSLatitude] = useState<number>();
	const [formSOSLongitude, setFormSOSLongitude] = useState<number>();
	const [formMap, setFormMap] = useState<google.maps.Map>();

	const { isLoading, isFetching, isRefetching, isFetched, refetch } = useQuery(["configuracoes", "homepoint", cliente!.id], () => fetchData(cliente!.id));
	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let respHome = await apiService.getHome(id);
		let respSOS = await apiService.getSOS(id);
		if (respHome.Result === 1 && respHome.Data && respSOS.Result === 1 && respSOS.Data) {
			setFormBaseLatitudeInicial(respHome.Data.latitude);
			setFormBaseLongitudeInicial(respHome.Data.longitude);
			setFormBaseLatitude(respHome.Data.latitude);
			setFormBaseLongitude(respHome.Data.longitude);

			setFormSOSLatitudeInicial(respSOS.Data.latitude);
			setFormSOSLongitudeInicial(respSOS.Data.longitude);
			setFormSOSLatitude(respSOS.Data.latitude);
			setFormSOSLongitude(respSOS.Data.longitude);

			if (respHome.Data.latitude && respHome.Data.longitude) {
				formMap?.setCenter({ lat: respHome.Data.latitude, lng: respHome.Data.longitude });
			}
		} else {
			handleToast(toastTitle, respHome.Message ?? respSOS.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	async function mutateData(data: CoordinatesModel[]) {
		let respHome = await apiService.postHome(cliente!.id, data[0]);
		let respSOS = await apiService.postSOS(cliente!.id, data[1]);
		return [respHome, respSOS];
	}

	function mutateSuccess(resp: ApiResponseType<number>[]) {
		if (resp[0].Result === 1 && resp[0].Data && resp[1].Result === 1 && resp[1].Data) {
			queryClient.invalidateQueries(["configuracoes", "homepoint", cliente!.id]);
			handleToast(toastTitle, `${resp[0].Message} - ${resp[1].Message}`, 5000);
			navigate("/homepointSOSFormulario");
		} else {
			handleToast(toastTitle, `${resp[0].Message} - ${resp[1].Message}`, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/");
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: CoordinatesModel[] = [
			{
				latitude: formBaseLatitude!,
				longitude: formBaseLongitude!,
			},
			{
				latitude: formSOSLatitude!,
				longitude: formSOSLongitude!,
			},
		];

		mutation.mutate(data);
	}

	function handleMapLoad({ map }: { map: Map; maps: any }) {
		var baseLatitude = formBaseLatitude;
		var baseLongitude = formBaseLongitude;
		var sosLatitude = formSOSLatitude;
		var sosLongitude = formSOSLongitude;

		map.addListener("click", (e: any) => {
			if (!baseLatitude && !baseLongitude) {
				baseLatitude = e.latLng.lat();
				baseLongitude = e.latLng.lng();
				setFormBaseLatitudeInicial(baseLatitude);
				setFormBaseLongitudeInicial(baseLongitude);
				setFormBaseLatitude(baseLatitude);
				setFormBaseLongitude(baseLongitude);
			} else if (!sosLatitude && !sosLongitude) {
				sosLatitude = e.latLng.lat();
				sosLongitude = e.latLng.lng();
				setFormSOSLatitudeInicial(sosLatitude);
				setFormSOSLongitudeInicial(sosLongitude);
				setFormSOSLatitude(sosLatitude);
				setFormSOSLongitude(sosLongitude);
			}
		});

		setFormMap(map);
	}

	function handleEndereco() {
		setFormEnderecoLoading(true);
		geocode(RequestType.ADDRESS, formEndereco, { key: `${process.env.REACT_APP_GOOGLE_MAPS_API_KEY}`, outputFormat: OutputFormat.JSON })
			.then((response) => {
				let location = response.results[0].geometry.location;
				formMap!.setCenter({ lat: location.lat, lng: location.lng });
			})
			.catch((error) => {
				console.error(error);
			})
			.finally(() => {
				setFormEnderecoLoading(false);
			});
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiAlphaHCircleOutline} size={1} className="me-1" />
				Formulário de Homepoint SOS
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="homepoint">
							<Form.Label>Homepoint / SOS</Form.Label>
							<InputGroup>
								<Form.Control
									type="text"
									placeholder="Informe aqui o endereço"
									value={formEndereco}
									onChange={(event) => {
										setFormEndereco(event.target.value);
									}}
									disabled={!formStatus}
								/>
								<Button onClick={handleEndereco} disabled={!formStatus || formEnderecoLoading}>
									{formEnderecoLoading ? <Spinner size="sm" /> : <Icon path={mdiMapSearch} size={1} />}
								</Button>
							</InputGroup>
							<div className={`position-relative img-thumbnail`} style={{ height: 500, background: !formStatus ? "var(--bs-secondary-bg)" : "" }}>
								{!isFetching && isFetched && (
									<GoogleMap
										apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
										defaultZoom={15}
										defaultCenter={{ lat: formBaseLatitude ?? -30.033056, lng: formBaseLongitude ?? -51.23 }}
										onGoogleApiLoaded={handleMapLoad}
									>
										{formBaseLatitudeInicial && formBaseLongitudeInicial && (
											<Marker
												lat={formBaseLatitudeInicial}
												lng={formBaseLongitudeInicial}
												draggable={true}
												onDragEnd={(e: any, { latLng }: any) => {
													setFormBaseLatitude(latLng.lat);
													setFormBaseLongitude(latLng.lng);
												}}
											>
												<Icon
													path={mdiAlphaHCircleOutline}
													size={1.5}
													className="text-danger bg-white rounded-circle"
													style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
												/>
											</Marker>
										)}
										{formSOSLatitudeInicial && formSOSLongitudeInicial && (
											<Marker
												lat={formSOSLatitudeInicial}
												lng={formSOSLongitudeInicial}
												draggable={true}
												onDragEnd={(e: any, { latLng }: any) => {
													setFormSOSLatitude(latLng.lat);
													setFormSOSLongitude(latLng.lng);
												}}
											>
												<Icon
													path={mdiAlphaHCircleOutline}
													size={1.5}
													className="text-air-blue bg-white rounded-circle"
													style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
												/>
											</Marker>
										)}
									</GoogleMap>
								)}
							</div>
							<Form.Text className="d-flex align-items-center">
								Clique no local desejado no mapa para adicionar o
								<Icon path={mdiAlphaHCircleOutline} size={1} className="text-danger ms-1" /> <span className="fw-bold">Homepoint</span>&nbsp;e o
								<Icon path={mdiAlphaHCircleOutline} size={1} className="text-air-blue ms-1" /> <span className="fw-bold">SOS</span>
							</Form.Text>
						</Form.Group>

						{!formStatus ? (
							<Button
								className="me-2"
								variant="dark"
								type="button"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						) : (
							<>
								<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
									Cancelar
								</Button>
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</Layout>
	);
}

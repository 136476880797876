import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiCogOutline } from "@mdi/js";

import { ResumoClienteModel } from "../../models/ResumoClienteModel";
import ListCard from "../ListCard";
import { useAuth } from "../../context/AuthContext";
import { Button } from "react-bootstrap";

type ClienteResumoCardPropsContent = {
	data: ResumoClienteModel;
	skeleton?: never;
};

type ClienteResumoCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type ClienteResumoCardProps = ClienteResumoCardPropsContent | ClienteResumoCardPropsSkeleton;

export default function ClienteResumoCard(props: ClienteResumoCardProps) {
	const navigate = useNavigate();
	const { handleCliente } = useAuth();

	function handleClick() {
		if (!props.skeleton && props.data) {
			handleCliente(props.data);
			navigate("/dashboard");
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Cliente</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Número de Voos</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.numeroVoos ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Horas de Vôo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.tempoVoo ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Distância de Vôo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.distanciaVoo ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Button
						variant="light"
						className={`me-2 ${skeletonButton}`}
						onClick={(e) => {
							e.preventDefault();
							e.stopPropagation();
							navigate("/configuracoesFormulario/" + props.data?.id + "/" + props.data?.nome);
						}}
					>
						<Icon path={mdiCogOutline} size={1} />
					</Button>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}

import { useEffect, useState } from "react";
import { Button, Card, Form, InputGroup, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiQuadcopter } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { DroneModel } from "../../models/DroneModel";

import Layout from "../../components/Layout";

const toastTitle = "Drone";

export default function DroneFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { user, cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const clienteAtual = user!.getCliente(cliente!.id);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);
	const [formNome, setFormNome] = useState<string>();
	const [formCodigo, setFormCodigo] = useState<string>();
	const [formSerial, setFormSerial] = useState<string>();
	const [formData, setFormData] = useState<Moment>();
	const [formDataInicio, setFormDataInicio] = useState<Moment>();
	const [formModelo, setFormModelo] = useState<number>();
	const [formObservacao, setFormObservacao] = useState<string>();
	const [formMatriculaAnac, setFormMatriculaAnac] = useState<string>();
	const [formApolice, setFormApolice] = useState<string>();
	const [formValidadeApolice, setFormValidadeApolice] = useState<Moment>();

	const { isLoading, isFetching, isRefetching, refetch } = useQuery(["drone", id], () => fetchData(id), { enabled: id !== undefined });
	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetCadastroDrone(id);
		if (resp.Result === 1 && resp.Data) {
			setFormNome(resp.Data.nome);
			setFormCodigo(resp.Data.codigo);
			setFormSerial(resp.Data.serial);
			if (resp.Data.data?.ticks) {
				setFormData(moment(resp.Data.data.ticks));
			}
			if (resp.Data.dataInicio?.ticks) {
				setFormDataInicio(moment(resp.Data.dataInicio.ticks));
			}
			setFormModelo(resp.Data.modelo);
			setFormObservacao(resp.Data.observacao);
			setFormMatriculaAnac(resp.Data.matriculaAnac);
			setFormApolice(resp.Data.apolice);
			if (resp.Data.validadeApolice?.ticks) {
				setFormValidadeApolice(moment(resp.Data.validadeApolice.ticks));
			}
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data: DroneModel = {
			id: Number(id) ?? null,
			foto: null,
			nome: formNome!,
			codigo: formCodigo!,
			serial: formSerial!,
			data: formData ? { ticks: formData!.valueOf(), dateString: formData!.toLocaleString() } : undefined,
			dataInicio: formDataInicio ? { ticks: formDataInicio!.valueOf(), dateString: formDataInicio!.toLocaleString() } : undefined,
			modelo: formModelo!,
			observacao: formObservacao!,
			matriculaAnac: formMatriculaAnac!,
			apolice: formApolice!,
			validadeApolice: formValidadeApolice ? { ticks: formValidadeApolice!.valueOf(), dateString: formValidadeApolice!.toLocaleString() } : undefined,
			tempoVoo: null,
		};

		mutation.mutate(data);
	}

	async function mutateData(data: DroneModel) {
		return await apiService.PostCadastroDrone(data, cliente!.id, clienteAtual!.empresaId);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["drone", id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/droneFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/drones");
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/drones"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiQuadcopter} size={1} className="me-1" />
					Formulário de Drone
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Form onSubmit={handleSubmit}>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="codigo">
							<Form.Label>Código</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o código"
								value={formCodigo}
								onChange={(event) => {
									setFormCodigo(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="serial">
							<Form.Label>Serial</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o serial"
								value={formSerial}
								onChange={(event) => {
									setFormSerial(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="data">
							<Form.Label>Data</Form.Label>
							<ReactDatePicker
								placeholderText="Data"
								className="form-control"
								calendarClassName="shadow-lg"
								onChange={(data) => {
									setFormData(data ? moment(data) : undefined);
								}}
								selected={formData?.toDate()}
								dateFormat="dd/MM/yyyy"
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="dataInicio">
							<Form.Label>Data Início</Form.Label>
							<ReactDatePicker
								placeholderText="Data"
								className="form-control"
								calendarClassName="shadow-lg"
								onChange={(data) => {
									setFormDataInicio(data ? moment(data) : undefined);
								}}
								selected={formDataInicio?.toDate()}
								dateFormat="dd/MM/yyyy"
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="modelo">
							<Form.Label>Modelo</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui o modelo"
								value={formModelo}
								onChange={(event) => {
									setFormModelo(Number(event.target.value));
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="observacao">
							<Form.Label>Observação</Form.Label>
							<Form.Control
								type="number"
								placeholder="Informe aqui a observação"
								value={formObservacao}
								onChange={(event) => {
									setFormObservacao(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="matriculaAnac">
							<Form.Label>Matrícula Anac</Form.Label>
							<InputGroup>
								<InputGroup.Text>http://</InputGroup.Text>
								<Form.Control
									type="number"
									placeholder="Informe aqui a matrícula anac"
									value={formMatriculaAnac}
									onChange={(event) => {
										setFormMatriculaAnac(event.target.value);
									}}
									disabled={!formStatus}
								/>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="apolice">
							<Form.Label>Apólice</Form.Label>
							<InputGroup>
								<InputGroup.Text>http://</InputGroup.Text>
								<Form.Control
									type="number"
									placeholder="Informe aqui a apólice"
									value={formApolice}
									onChange={(event) => {
										setFormApolice(event.target.value);
									}}
									disabled={!formStatus}
								/>
							</InputGroup>
						</Form.Group>
						<Form.Group className="mb-3" controlId="validadeApolice">
							<Form.Label>Validade Apólice</Form.Label>
							<ReactDatePicker
								placeholderText="Data Validade Apólice"
								className="form-control"
								calendarClassName="shadow-lg"
								onChange={(data) => {
									setFormValidadeApolice(data ? moment(data) : undefined);
								}}
								selected={formValidadeApolice?.toDate()}
								dateFormat="dd/MM/yyyy"
								disabled={!formStatus}
							/>
						</Form.Group>

						{!formStatus ? (
							<Button
								className="me-2"
								variant="dark"
								type="button"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						) : (
							<>
								<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								{id && (
									<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
										Cancelar
									</Button>
								)}
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</Layout>
	);
}

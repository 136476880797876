import { Alert, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiBroadcastOff } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import { gridTemplate } from "../../config/defines";

import LiveCard from "../../components/LiveCard";
import Layout from "../../components/Layout";

export default function Lives() {
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const queryLives = useQuery(["lives"], () => fetchDataLives(), {
		refetchOnWindowFocus: false,
		refetchInterval: 10000,
	});

	async function fetchDataLives() {
		return await apiService.GetLives();
	}

	const livesCount = queryLives.data?.Data?.length ?? 0;
	const livesGrid = gridTemplate(livesCount);

	return (
		<Layout>
			<div className="my-4" style={{ display: "grid", gridTemplateColumns: livesGrid.gridTemplateColumns, minHeight: "calc(100dvh - 58px - 3rem)" }}>
				{queryLives.isLoading ? (
					<div className="d-flex justify-content-center align-items-center">
						<Spinner />
					</div>
				) : livesCount >= 1 ? (
					queryLives.data?.Data?.map((data, index) => {
						return (
							<div key={data.vooId} style={{ gridColumn: livesGrid.gridColumns[index] }}>
								<LiveCard data={data} />
							</div>
						);
					})
				) : (
					<Alert variant="light" className="text-center p-4 d-flex flex-column justify-content-center align-items-center">
						<Icon path={mdiBroadcastOff} size={4} className="me-2" />
						Nenhuma Live no momento.
						<span className="text-muted mt-3 small">Quando houver Lives, elas serão exibidas automaticamente.</span>
					</Alert>
				)}
			</div>
		</Layout>
	);
}

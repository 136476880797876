import { useEffect, useState } from "react";
import { Alert, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline, mdiTextBoxOutline } from "@mdi/js";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";

import { SelectGroupStyle } from "../../config/select";
import ApiService from "../../services/ApiService";
import { useAuth } from "../../context/AuthContext";

import ClienteLogCard from "../../components/ClienteLogCard";
import Layout from "../../components/Layout";

export default function Logs() {
	const { cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const queryDrones = useQuery(["drones", cliente!.id], () => fetchDataDrones());

	const [filtroDrone, setFiltroDrone] = useState<{ label: string; value: number } | undefined>(
		queryDrones.data?.Data && queryDrones.data?.Data?.length >= 1 ? { label: queryDrones.data?.Data.first().nome, value: queryDrones.data?.Data.first().id } : undefined
	);
	const [filtroDataInicial, setFiltroDataInicial] = useState<Moment | null>(moment().subtract(30, "days"));
	const [filtroDataFinal, setFiltroDataFinal] = useState<Moment | null>(moment());

	const { data, isLoading, isRefetching, refetch } = useQuery(["cliente", "logs", filtroDrone?.value], () => fetchData(), {
		enabled: !!filtroDrone,
	});

	useEffect(() => {
		if (!!filtroDrone && !!filtroDataInicial && !!filtroDataFinal) {
			refetch();
		}

		// eslint-disable-next-line
	}, [filtroDataInicial, filtroDataFinal]);

	useEffect(() => {
		let drone = sessionStorage.getItem("logsDrone");
		if (drone) {
			setFiltroDrone(JSON.parse(drone));
		}
		let logsDataInicial = sessionStorage.getItem("logsDataInicial");
		if (logsDataInicial && logsDataInicial !== "") {
			setFiltroDataInicial(moment(Number(logsDataInicial)));
		}
		let logsDataFinal = sessionStorage.getItem("logsDataFinal");
		if (logsDataFinal && logsDataFinal !== "") {
			setFiltroDataFinal(moment(Number(logsDataFinal)));
		}
	}, []);

	async function fetchData() {
		return await apiService.GetLogsDiarios(filtroDrone!.value, filtroDataInicial!.valueOf(), filtroDataFinal!.valueOf());
	}

	async function fetchDataDrones() {
		let resp = await apiService.GetDronesCliente(cliente!.id);
		if (!filtroDrone && resp.Data && resp.Data.length > 0) {
			setFiltroDrone({ label: resp.Data[0].nome, value: resp.Data[0].id });
		}
		return resp;
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap gap-2" style={{ minHeight: 38 }}>
				<div className="d-flex align-items-center">
					<Icon path={mdiTextBoxOutline} size={1} className="me-1" /> Logs Diários
					{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				</div>
				<div className="float-right ms-auto">
					<Row>
						<Col lg={6}>
							<InputGroup style={{ minWidth: 300 }}>
								<InputGroup.Text className="bg-white">Drone</InputGroup.Text>
								<Select
									placeholder={"Selecione"}
									className="fs-6 bg-white rounded"
									value={filtroDrone}
									noOptionsMessage={() => {
										return "Nenhuma opção";
									}}
									options={queryDrones.data?.Data?.map((drone) => {
										return { label: drone.nome, value: drone.id };
									})}
									onChange={(value) => {
										setFiltroDrone(value ?? undefined);
										sessionStorage.setItem("logsDrone", JSON.stringify(value));
									}}
									styles={SelectGroupStyle}
								/>
							</InputGroup>
						</Col>
						<Col lg={6}>
							<InputGroup style={{ minWidth: 300 }}>
								<InputGroup.Text className="bg-white">Período</InputGroup.Text>
								<ReactDatePicker
									placeholderText="Data inicial e final"
									className="form-control"
									calendarClassName="shadow-lg"
									onChange={(datas) => {
										setFiltroDataInicial(datas[0] ? moment(datas[0]) : null);
										setFiltroDataFinal(datas[1] ? moment(datas[1]).hours(23).minutes(59).seconds(59) : null);
										sessionStorage.setItem("logsDataInicial", datas[0] ? moment(datas[0]).valueOf().toString() : "");
										sessionStorage.setItem("logsDataFinal", datas[1] ? moment(datas[1]).hours(23).minutes(59).seconds(59).valueOf().toString() : "");
									}}
									startDate={filtroDataInicial?.toDate()}
									endDate={filtroDataFinal?.toDate()}
									maxDate={moment().toDate()}
									monthsShown={2}
									dateFormat="dd/MM/yyyy"
									selectsRange={true}
								/>
							</InputGroup>
						</Col>
					</Row>
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading || queryDrones.isLoading || !queryDrones.isFetched ? (
					<>
						{Array.from({ length: Number(process.env.REACT_APP_SKELETON_COUNT) }, (_, index) => {
							return (
								<Col md={12} key={index}>
									<ClienteLogCard skeleton />
								</Col>
							);
						})}
					</>
				) : data?.Result !== 1 || queryDrones.data?.Data?.length === 0 ? (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							{data?.Message ?? "Nenhum registro encontrado"}
						</Alert>
					</Col>
				) : (
					<>
						{!data?.Data?.length ? (
							<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
								<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
								Nenhum registro encontrado
							</Alert>
						) : (
							<>
								{data?.Data?.sort((a, b) => b.data.ticks - a.data.ticks).map((item, index) => {
									return (
										<Col md={12} key={index}>
											<ClienteLogCard data={item} />
										</Col>
									);
								})}
							</>
						)}
					</>
				)}
			</Row>
		</Layout>
	);
}

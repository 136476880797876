import { ReactNode } from "react";
import { Container } from "react-bootstrap";
import Header from "../Header";
import Body from "../Body";
import Main from "../Main";
import Sidebar from "../Sidebar";

type LayoutProps = {
	cliente?: boolean;
	children: ReactNode;
};

export default function Layout({ cliente = true, children }: LayoutProps) {
	return (
		<Container fluid className="layout" style={{ minHeight: "100vh" }}>
			<Body>
				<Sidebar />
				<Main>
					<Header cliente={cliente} />
					{children}
				</Main>
			</Body>
		</Container>
	);
}

import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import ScaleText from "react-scale-text";
import Icon from "@mdi/react";
import { mdiAirplane, mdiChevronRight, mdiClock, mdiDomainPlus, mdiMapMarkerDistance, mdiViewDashboardOutline } from "@mdi/js";

import { screens } from "../../config/screens";
import { useAuth } from "../../context/AuthContext";

import DashboardCard from "../../components/DashboardCard";
import ListCard from "../../components/ListCard";
import Layout from "../../components/Layout";

export default function Dashboard() {
	const navigate = useNavigate();
	const { user, cliente } = useAuth();

	if (!cliente) {
		navigate("/resumo");
	}

	function handleClick(item: string) {
		navigate(item);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiViewDashboardOutline} size={1} className="me-1" /> Dashboard
			</h5>

			<Row>
				<Col lg={4} className="mb-3">
					<DashboardCard
						icon={mdiAirplane}
						title="Número de Voos"
						value={
							<div className="fw-bold" style={{ width: "100%", height: 60 }}>
								<ScaleText>{cliente?.numeroVoos}</ScaleText>
							</div>
						}
					/>
				</Col>
				<Col lg={4} className="mb-3">
					<DashboardCard
						icon={mdiClock}
						title="Horas de Vôo"
						value={
							<div className="fw-bold" style={{ width: "100%", height: 60 }}>
								<ScaleText>{cliente?.tempoVoo}</ScaleText>
							</div>
						}
					/>
				</Col>
				<Col lg={4} className="mb-3">
					<DashboardCard
						icon={mdiMapMarkerDistance}
						title="Distância de Vôo"
						value={
							<div className="fw-bold" style={{ width: "100%", height: 60 }}>
								<ScaleText>{cliente?.distanciaVoo}</ScaleText>
							</div>
						}
					/>
				</Col>
			</Row>

			<Row className="mb-4">
				{screens
					.filter((screen) => {
						return (
							(screen.userTipos === undefined || screen.userTipos[user!.tipo] === true || screen.userTipos[user!.tipo] === undefined) &&
							(user!.permissoes.some((permissao) => {
								return screen.permissoes?.includes(permissao.codigo);
							}) ||
								screen.permissoes === undefined) &&
							screen.showDashboard
						);
					})
					.map((screen, screenKey) => {
						return (
							<Col lg={4} key={screenKey}>
								<ListCard
									onClick={() => {
										handleClick(screen.route);
									}}
								>
									<ListCard.Icon>
										<Icon path={screen.icon} size={1} className="text-muted me-3" />
									</ListCard.Icon>
									<ListCard.Content>
										<div className="flex-fill me-2">
											<div className="text-muted fw-lighter">{screen.name}</div>
											<div className="fw-light line-1">{screen.description}</div>
										</div>
									</ListCard.Content>
									<ListCard.Action>
										<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
									</ListCard.Action>
								</ListCard>
							</Col>
						);
					})}

				{(user!.canCreateAdmin() || user!.canCreateCliente() || user!.canCreateEmpresa()) && (
					<Col lg={4}>
						<ListCard
							onClick={() => {
								handleClick("/cadastros");
							}}
						>
							<ListCard.Icon>
								<Icon path={mdiDomainPlus} size={1} className="text-muted me-3" />
							</ListCard.Icon>
							<ListCard.Content>
								<div className="flex-fill me-2">
									<div className="text-muted fw-lighter">Cadastros</div>
									<div className="fw-light">Lista de cadastros</div>
								</div>
							</ListCard.Content>
							<ListCard.Action>
								<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
							</ListCard.Action>
						</ListCard>
					</Col>
				)}
			</Row>
		</Layout>
	);
}

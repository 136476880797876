import { Card, Col, Form, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { Link, useParams } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiTextBoxOutline } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";

import Layout from "../../components/Layout";

export default function Log() {
	const { id } = useParams();
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const { data, isLoading, isFetching, isRefetching } = useQuery(["log", id], () => fetchData(id), { enabled: id !== undefined });

	async function fetchData(id: any) {
		return await apiService.GetLogDiario(id);
	}

	let dateString = " ";
	if (data?.Data?.data.dateString) {
		dateString = data?.Data?.data.dateString;
		if (data?.Data?.tipo === 1) {
			dateString = dateString.substring(0, 10);
		}
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/logs"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiTextBoxOutline} size={1} className="me-1" />
					Log
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Row>
						<Col lg={3}>
							<Form.Group className="mb-3">
								<div className={`fw-lighter ${isLoading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Data</div>
								<div className={`fw-light ${isLoading ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{dateString}&nbsp;</div>
							</Form.Group>
						</Col>
						<Col lg={3}>
							<Form.Group className="mb-3">
								<div className={`fw-lighter ${isLoading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Drone</div>
								<div className={`fw-light ${isLoading ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.Data?.drone.nome}&nbsp;</div>
							</Form.Group>
						</Col>
						<Col lg={3}>
							<Form.Group className="mb-3">
								<div className={`fw-lighter ${isLoading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Tipo</div>
								<div className={`fw-light ${isLoading ? "skeleton skeleton-text skeleton-text-text" : ""}`}>
									{data?.Data?.tipo ? (data?.Data?.tipo === 1 ? "Vôo" : "Crash") : "..."}&nbsp;
								</div>
							</Form.Group>
						</Col>
						<Col lg={3}>
							<Form.Group className="mb-3">
								<div className={`fw-lighter ${isLoading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Usuário</div>
								<div className={`fw-light ${isLoading ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.Data?.usuario.nome}&nbsp;</div>
							</Form.Group>
						</Col>
						<Col lg={12}>
							<Form.Group className="mb-3">
								<div className={`fw-lighter ${isLoading ? "skeleton skeleton-text skeleton-text-title" : ""}`}>Conteúdo</div>
								<pre className={`bg-light border rounded p-2 fw-light ${isLoading ? "skeleton skeleton-text skeleton-text-text" : ""}`}>{data?.Data?.conteudo}</pre>
							</Form.Group>
						</Col>
					</Row>
				</Card.Body>
			</Card>
		</Layout>
	);
}

import { Card } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";
import { ReactNode } from "react";

type DashboardCardProps = {
	title: string;
	subtitle?: string;
	icon: string | ReactNode;
	iconClass?: string;
	value: string | ReactNode;
	valueDescription?: string | ReactNode;
	loading?: boolean;
	onClick?: () => void;
};

export default function DashboardCard(props: DashboardCardProps) {
	return (
		<Card className="border-0 shadow" onClick={props.onClick} style={{ cursor: props.onClick ? "pointer" : "" }}>
			<Card.Body className="d-flex flex-row justify-content-between gap-2 p-4">
				<div className="d-flex flex-column flex-fill">
					<div className={`d-flex align-items-center line-1 small`}>{props.title}</div>
					<div className={`d-flex align-items-center lh-1 ${props.loading ? "skeleton skeleton-text skeleton-text-title" : ""}`} style={{ minHeight: 60 }}>
						{typeof props.value === "string" ? <div className="fw-bold fs-1">{props.value}</div> : props.value}
						{props.valueDescription}
					</div>
					{!props.loading && props.subtitle && <div className={`d-flex align-items-center line-1 fw-light text-muted small`}>{props.subtitle}</div>}
				</div>
				<div className="d-flex flex-row align-items-center justify-content-center">
					{typeof props.icon === "string" ? <Icon path={props.icon} className={`${props.iconClass}`} style={{ width: 60 }} /> : props.icon}
					{props.onClick && <Icon path={mdiChevronRight} size={1} className={`text-muted`} style={{ marginRight: -12 }} />}
				</div>
			</Card.Body>
		</Card>
	);
}

import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { Relatorio } from "../../models/Relatorio";

import ListCard from "../ListCard";

type RelatorioCardPropsContent = {
	data: Relatorio;
	skeleton?: never;
};

type RelatorioCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type RelatorioCardProps = RelatorioCardPropsContent | RelatorioCardPropsSkeleton;

export default function RelatorioCard(props: RelatorioCardProps) {
	const navigate = useNavigate();

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/relatorioFormulario/" + props.data.id);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Titulo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.titulo ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Tipo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.tipoRelatorio.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Data</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.data.dateString}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Modificado</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.dataAlteracao.dateString}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}

import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import DatePicker from "react-datepicker";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiDownloadOutline, mdiTrashCanOutline } from "@mdi/js";
// import { mdiFileDocumentOutline } from "@mdi/js";

import { DroneExtModel } from "../../models/DroneModel";

import ListCard from "../ListCard";
import moment, { Moment } from "moment";
import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";
import { ContentDispositionHelpers } from "../../utils/ContentDispositionHelpers";

type DroneCardPropsContent = {
	data: DroneExtModel;
	skeleton?: never;
};

type DroneCardPropsSkeleton = {
	data?: never;
	onClick?: never;
	onRemove?: never;
	skeleton: true;
};

type DroneCardProps = DroneCardPropsContent | DroneCardPropsSkeleton;

export default function DroneCard(props: DroneCardProps) {
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
	const [removed, setRemoved] = useState(false);

	const [exportModal, setExportModal] = useState(false);
	const [exportLoadingModal, setExportLoadingModal] = useState(false);
	const [exportDataInicial, setExportDataInicial] = useState<Moment | null>(moment().subtract(30, "days"));
	const [exportDataFinal, setExportDataFinal] = useState<Moment | null>(moment());

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/droneFormulario/" + props.data.id);
		}
	}

	// function handleClickReport(e: any) {
	// 	e.stopPropagation();
	// 	if (!props.skeleton && props.data) {
	// 		navigate("/relatorios?droneId=" + props.data.id);
	// 	}
	// }

	function handleClickExport(e: any) {
		e.stopPropagation();
		setExportModal(true);
	}

	function handleCloseExport() {
		if (!exportLoadingModal) {
			setExportModal(false);
		}
	}

	function handleClickRemove(e: any) {
		e.stopPropagation();
		setRemoveModal(true);
	}

	function handleCloseRemove() {
		if (!removeLoadingModal) {
			setRemoveModal(false);
		}
	}

	async function handleRemove() {
		if (!props.skeleton && props.data) {
			setRemoveLoadingModal(true);
			setRemoved(true);
			setRemoveModal(false);
			setRemoveLoadingModal(false);
		}
	}

	async function handleExport() {
		setExportLoadingModal(true);
		try {
			var resp: any = await apiService.GetStatusVooCsvDrone(props.data!.id, exportDataInicial!.valueOf(), exportDataFinal!.valueOf());
			if (resp.status === 200) {
				var blob = resp.data;
				var a = document.createElement("a");
				var url = window.URL.createObjectURL(blob);
				a.href = url;
				var filename = "statusvoo_drone.csv";
				try {
					var cd = resp.headers.get("content-disposition");
					if (cd) {
						var fn = ContentDispositionHelpers.getFilename(cd);
						if (fn.length > 0) filename = fn;
					}
				} catch (e) {
					console.log(e);
				}
				a.download = filename;
				a.click();
				window.URL.revokeObjectURL(url);
			}
		} catch (e) {
			console.log(e);
		}
		setExportModal(false);
		setExportLoadingModal(false);
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	if (removed) {
		return <></>;
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Serial</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.serial ?? "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					{/* <Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickReport}>
						<Icon path={mdiFileDocumentOutline} size={1} />
					</Button> */}
					<Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickExport}>
						<Icon path={mdiDownloadOutline} size={1} />
						CSV
					</Button>
					<Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}>
						<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
					</Button>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>

			<Modal show={removeModal} onHide={handleCloseRemove} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remover Drone</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover este drone?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseRemove}>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleRemove}>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>

			<Modal show={exportModal} onHide={handleCloseExport} centered size="lg">
				<Modal.Header closeButton>
					<Modal.Title>CSV Período</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">
					{!exportLoadingModal ? (
						<DatePicker
							placeholderText="Data inicial e final"
							className="form-control"
							calendarClassName="border"
							onChange={(datas) => {
								setExportDataInicial(datas[0] ? moment(datas[0]) : null);
								setExportDataFinal(datas[1] ? moment(datas[1]).hours(23).minutes(59).seconds(59) : null);
							}}
							startDate={exportDataInicial?.toDate()}
							endDate={exportDataFinal?.toDate()}
							maxDate={moment().toDate()}
							dateFormat="dd/MM/yyyy"
							selectsRange={true}
							inline={true}
							monthsShown={2}
						/>
					) : (
						<Spinner />
					)}
				</Modal.Body>
				{!exportLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseExport}>
							Cancelar
						</Button>
						<Button variant="primary" onClick={handleExport}>
							Confirmar
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
}

import { ReactNode } from "react";
import { Container } from "react-bootstrap";

type MainProps = {
    children: ReactNode[];
}

export default function Main({children}: MainProps){
	return(
		<Container className="col-md-9 ms-sm-auto col-lg-9 col-xl-10 px-md-4 me-0">
			{ children.filter((item)=>{ return item }) }
		</Container>
	)
}
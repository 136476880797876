import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { LogDiarioLiteModel } from "../../models/LogDiarioModel";

import ListCard from "../ListCard";

type ClienteLogCardPropsContent = {
	data: LogDiarioLiteModel;
	skeleton?: never;
};

type ClienteLogCardPropsSkeleton = {
	data?: never;
	skeleton: true;
};
// tipo 1: voo
// tipo 2: crash
type ClienteLogCardProps = ClienteLogCardPropsContent | ClienteLogCardPropsSkeleton;

export default function ClienteLogCard(props: ClienteLogCardProps) {
	const navigate = useNavigate();

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/log/" + props.data.id);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	let dateString = "...";
	if (props.data?.data.dateString) {
		dateString = props.data?.data.dateString;
		if (props.data?.tipo === 1) {
			dateString = dateString.substring(0, 10);
		}
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Data</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{dateString}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Drone</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.drone.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Tipo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.tipo ? (props.data?.tipo === 1 ? "Vôo" : "Crash") : "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}

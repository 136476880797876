import { Alert, Button, Card, Col, Row, Spinner, Table } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import { useQuery } from "react-query";
import ScaleText from "react-scale-text";
import Icon from "@mdi/react";
import {
	mdiAccountTieHatOutline,
	mdiBatteryHigh,
	mdiCardRemoveOutline,
	mdiChevronRight,
	mdiGauge,
	mdiQuadcopter,
	mdiThermometer,
	mdiViewDashboardVariantOutline,
	mdiWaterPercent,
	mdiWeatherWindy,
} from "@mdi/js";

import { convertBeaufortColor, convertBeaufortDescription, convertBeaufortValue } from "../../config/defines";
import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";

import DashboardCard from "../../components/DashboardCard";
import Layout from "../../components/Layout";

export default function Status() {
	const navigate = useNavigate();
	const { user, cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const queryWeather = useQuery(["weather", cliente!.id], () => fetchDataWeather(), { refetchInterval: 60000 });
	const queryDrones = useQuery(["drones", cliente!.id, "resumo"], () => fetchDataDrones());
	const queryPilotos = useQuery(["pilotos", cliente!.id, "resumo"], () => fetchDataPilotos());
	const queryBaterias = useQuery(["baterias", cliente!.id, "resumo"], () => fetchDataBaterias());

	async function fetchDataWeather() {
		return await apiService.GetCurrentWeather(cliente!.id);
	}

	async function fetchDataDrones() {
		return await apiService.GetResumoDronesCliente(cliente!.id);
	}

	async function fetchDataPilotos() {
		return await apiService.GetResumoPilotoCliente(cliente!.id);
	}

	async function fetchDataBaterias() {
		return await apiService.GetResumoBateriaCliente(cliente!.id);
	}

	let beaufort = convertBeaufortValue(Number(queryWeather.data?.Data?.wind.speed));
	let beaufortDescription = convertBeaufortDescription(beaufort);
	let beaufortColor = convertBeaufortColor(beaufort);

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiViewDashboardVariantOutline} size={1} className="me-1" /> Status
				{queryWeather.isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			{queryWeather.isLoading || queryWeather.data?.Result === 1 ? (
				<Row>
					<Col md={4} className="mb-3">
						<DashboardCard
							icon={mdiWeatherWindy}
							title="Vento Velocidade"
							value={`${(Number(queryWeather.data?.Data?.wind.speed) * 3.6).toFixed(1)} km/h`}
							loading={queryWeather.isLoading}
						/>
					</Col>
					<Col md={4} className="mb-3">
						<DashboardCard
							icon={queryWeather.isLoading ? undefined : <i className={`wi wi-wind towards-${queryWeather.data?.Data?.wind.deg}-deg`} style={{ fontSize: 60 }}></i>}
							title="Vento Direção"
							value={`${queryWeather.data?.Data?.wind.deg}˚`}
							loading={queryWeather.isLoading}
						/>
					</Col>
					<Col md={4} className="mb-3">
						<DashboardCard
							icon={queryWeather.isLoading ? undefined : <i className={`wi wi-wind-beaufort-${beaufort} ${beaufortColor}`} style={{ fontSize: 60 }}></i>}
							title="Vento Intensidade"
							// value={`${beaufortDescription}`}
							value={
								<div className="fw-bold" style={{ width: "100%", height: 60 }}>
									<ScaleText>{beaufortDescription}</ScaleText>
								</div>
							}
							loading={queryWeather.isLoading}
						/>
					</Col>
					<Col md={6} className="mb-3">
						<DashboardCard
							icon={<i className={`wi wi-owm-${queryWeather.data?.Data?.weather.first().id}`} style={{ fontSize: 60 }}></i>}
							title={`Clima em ${queryWeather.data?.Data?.name}`}
							value={`${queryWeather.data?.Data?.weather.first().description.toCapitalize()}`}
							loading={queryWeather.isLoading}
						/>
					</Col>
					<Col md={6} className="mb-3">
						<DashboardCard icon={mdiThermometer} title="Temperatura" value={`${queryWeather.data?.Data?.main.temp.toFixed(0)}°C`} loading={queryWeather.isLoading} />
					</Col>
					<Col md={6} className="mb-3">
						<DashboardCard icon={mdiWaterPercent} title="Umidade" value={`${queryWeather.data?.Data?.main.humidity}%`} loading={queryWeather.isLoading} />
					</Col>
					<Col md={6} className="mb-3">
						<DashboardCard icon={mdiGauge} title="Pressão Atmosférica" value={`${queryWeather.data?.Data?.main.pressure} hPa`} loading={queryWeather.isLoading} />
					</Col>
				</Row>
			) : (
				queryWeather.data?.Message && (
					<Row>
						<Col className="mb-3">
							<Card className="border-0 shadow">
								<Card.Body className="d-flex flex-row justify-content-center gap-2 p-4">{queryWeather.data?.Message}</Card.Body>
							</Card>
						</Col>
					</Row>
				)
			)}

			<Row>
				<Col lg={12} className="mb-3">
					<Card className="border-0 shadow p-4">
						<Card.Body className="p-0 small">
							<div className="d-flex align-items-center justify-content-between">
								<div>Drones {queryDrones.isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}</div>
								<Icon path={mdiQuadcopter} size={1} className="me-1 float-end" />
							</div>
							<Row>
								{queryDrones.isLoading ? (
									<Col md={12} className="text-center">
										<Spinner />
									</Col>
								) : queryDrones.data?.Result !== 1 ? (
									<Col md={12}>
										<Alert variant="secondary" className="text-center">
											{queryDrones.data?.Message}
										</Alert>
									</Col>
								) : (
									<>
										{!queryDrones.data?.Data?.length ? (
											<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
												<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
												Nenhum registro encontrado
											</Alert>
										) : (
											<>
												<Col md={12}>
													<Table className="mb-0" hover>
														<thead>
															<tr>
																<th>Nome</th>
																<th>Distância</th>
																<th>Tempo Vôo</th>
																<th>Voos</th>
																{user!.tipo !== 1 && <th></th>}
															</tr>
														</thead>
														<tbody>
															{queryDrones.data?.Data?.map((item, index) => {
																return (
																	<tr
																		key={index}
																		onClick={() => {
																			if (user!.tipo !== 1) {
																				navigate("/droneFormulario/" + item.id);
																			}
																		}}
																		style={{ cursor: "pointer" }}
																	>
																		<td className="col-lg-3">{item.nome}</td>
																		<td>{item.distanciaVoo}</td>
																		<td>{item.tempoVoo}</td>
																		<td>{item.numeroVoos}</td>
																		{user!.tipo !== 1 && (
																			<td className="text-end">
																				<Icon path={mdiChevronRight} size={1} className={`text-muted`} />
																			</td>
																		)}
																	</tr>
																);
															})}
														</tbody>
													</Table>
												</Col>
											</>
										)}
									</>
								)}
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			<Row>
				<Col lg={12} className="mb-3">
					<Card className="border-0 shadow p-4">
						<Card.Body className="p-0 small">
							<div className="d-flex align-items-center justify-content-between">
								<div>Pilotos {queryDrones.isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}</div>
								<Icon path={mdiAccountTieHatOutline} size={1} className="me-1 float-end" />
							</div>
							<Row>
								{queryPilotos.isLoading ? (
									<Col md={12} className="text-center">
										<Spinner />
									</Col>
								) : queryPilotos.data?.Result !== 1 ? (
									<Col md={12}>
										<Alert variant="secondary" className="text-center">
											{queryPilotos.data?.Message}
										</Alert>
									</Col>
								) : (
									<>
										{!queryPilotos.data?.Data?.length ? (
											<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
												<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
												Nenhum registro encontrado
											</Alert>
										) : (
											<>
												<Col md={12}>
													<Table className="mb-0" hover>
														<thead>
															<tr>
																<th>Nome</th>
																<th>Distância</th>
																<th>Tempo Vôo</th>
																<th>Voos</th>
																{user!.tipo !== 1 && <th></th>}
															</tr>
														</thead>
														<tbody>
															{queryPilotos.data?.Data?.map((item, index) => {
																return (
																	<tr
																		key={index}
																		onClick={() => {
																			if (user!.tipo !== 1) {
																				navigate("/pilotoFormulario/" + item.id);
																			}
																		}}
																		style={{ cursor: "pointer" }}
																	>
																		<td>{item.nome}</td>
																		<td>{item.distanciaVoo}</td>
																		<td>{item.tempoVoo}</td>
																		<td>{item.numeroVoos}</td>
																		{user!.tipo !== 1 && (
																			<td className="text-end">
																				<Icon path={mdiChevronRight} size={1} className={`text-muted`} />
																			</td>
																		)}
																	</tr>
																);
															})}
														</tbody>
													</Table>
												</Col>
											</>
										)}
									</>
								)}
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row>

			{/* <Row>
				<Col lg={12} className="mb-3">
					<Card className="border-0 shadow p-4">
						<Card.Body className="p-0 small">
							<div className="d-flex align-items-center justify-content-between">
								<div>Baterias {queryDrones.isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}</div>
								<Icon path={mdiBatteryHigh} size={1} className="me-1 float-end" />
							</div>
							<Row>
								{queryBaterias.isLoading ? (
									<Col md={12} className="text-center">
										<Spinner />
									</Col>
								) : queryBaterias.data?.Result !== 1 ? (
									<Col md={12}>
										<Alert variant="secondary" className="text-center">
											{queryBaterias.data?.Message}
										</Alert>
									</Col>
								) : (
									<>
										{!queryBaterias.data?.Data?.length ? (
											<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
												<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
												Nenhum registro encontrado
											</Alert>
										) : (
											<>
												<Col md={12}>
													<Table className="mb-0" hover>
														<thead>
															<tr>
																<th>N˚ Série</th>
																<th>Situação</th>
																<th>N˚ Voos</th>
																<th>Tempo Vôo</th>
																<th>Ciclos Carga</th>
																{user!.tipo !== 1 <th></th>}
															</tr>
														</thead>
														<tbody>
															{queryBaterias.data?.Data?.map((item, index) => {
																return (
																	<tr
																		key={index}
																		onClick={() => {
																			if (user!.tipo !== 1) {
																				navigate("/droneFormulario/" + item.id);
																			}
																		}}
																		style={{ cursor: "pointer" }}
																	>
																		<td>{item.nome}</td>
																		<td>{item.distanciaVoo}</td>
																		<td>{item.tempoVoo}</td>
																		<td>{item.numeroVoos}</td>
																		{user!.tipo !== 1 && (
																			<td className="text-end">
																				<Icon path={mdiChevronRight} size={1} className={`text-muted`} />
																			</td>
																		)}
																	</tr>
																);
															})}
														</tbody>
													</Table>
												</Col>
											</>
										)}
									</>
								)}
							</Row>
						</Card.Body>
					</Card>
				</Col>
			</Row> */}
		</Layout>
	);
}

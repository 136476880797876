import { useNavigate, useSearchParams } from "react-router-dom";
import { Alert, Button, Col, Row, Spinner } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline, mdiNewspaperVariantOutline } from "@mdi/js";
import { useQuery } from "react-query";

import Layout from "../../components/Layout";
import ApiService from "../../services/ApiService";
import { useAuth } from "../../context/AuthContext";
import RelatorioCard from "../../components/RelatorioCard";

export default function Relatorios() {
	const navigate = useNavigate();
	const { handleLogout } = useAuth();
	const [searchParams] = useSearchParams();

	const apiService = new ApiService(handleLogout);

	const droneId = searchParams.get("droneId");
	const bateriaId = searchParams.get("bateriaId");

	const { data, isLoading, isRefetching } = useQuery(["relatorios", droneId, bateriaId], () => fetchData());

	async function fetchData() {
		return await apiService.GetRelatorios(droneId ? Number(droneId) : undefined, bateriaId ? Number(bateriaId) : undefined);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiNewspaperVariantOutline} size={1} className="me-1" /> Relatórios
				{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button
						variant="air-blue"
						className="text-white"
						onClick={() => {
							navigate("/relatorioFormulario");
						}}
					>
						Cadastrar
					</Button>{" "}
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading ? (
					<>
						{Array.from({ length: Number(process.env.REACT_APP_SKELETON_COUNT) }, (_, index) => {
							return (
								<Col md={12} key={index}>
									<RelatorioCard skeleton />
								</Col>
							);
						})}
					</>
				) : data?.Result !== 1 ? (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							{data?.Message ?? "Nenhum registro encontrado"}
						</Alert>
					</Col>
				) : (
					<>
						{!data?.Data?.length ? (
							<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
								<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
								Nenhum registro encontrado
							</Alert>
						) : (
							<>
								{data?.Data?.map((item, index) => {
									return (
										<Col md={12} key={index}>
											<RelatorioCard data={item} />
										</Col>
									);
								})}
							</>
						)}
					</>
				)}
			</Row>
		</Layout>
	);
}

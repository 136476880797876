import { Alert, Button, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import { useNavigate } from "react-router-dom";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiCardRemoveOutline, mdiMapMarkerPath } from "@mdi/js";

import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import ApiService from "../../services/ApiService";

import Layout from "../../components/Layout";
import MissaoCard from "../../components/MissaoCard";
import { SelectGroupStyle } from "../../config/select";
import { useState } from "react";

const formStatusOptions = [
	{ label: "Ativo", value: 1 },
	{ label: "Inativo", value: 0 },
];

export default function Missoes() {
	const navigate = useNavigate();
	const { cliente, handleLogout } = useAuth();
	const { handleToast } = useToast();
	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(formStatusOptions.first());

	const queryHomepoint = useQuery(["homepoint", cliente!.id], () => fetchDataHomepoint());
	const { data, isLoading, isRefetching } = useQuery(["missoes", cliente!.id], () => fetchData(), { enabled: queryHomepoint.data?.Result === 1 });

	async function fetchData() {
		let resp = await apiService.GetMissoesCliente(cliente!.id);
		if (resp.Result !== 1) {
			handleToast("Missões", resp.Message, 5000, "warning");
		}
		return resp;
	}

	async function fetchDataHomepoint() {
		let respHome = await apiService.getHome(cliente!.id);
		let respSOS = await apiService.getSOS(cliente!.id);
		if (respHome.Result !== 1) {
			return { ...respHome, Data: undefined };
		}
		if (respSOS.Result !== 1) {
			return { ...respSOS, Data: undefined };
		}
		return {
			Result: 1,
			Message: undefined,
			Data: { homepoint: respHome.Data, sos: respSOS.Data },
		};
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap gap-2" style={{ minHeight: 38 }}>
				<div className="d-flex align-items-center">
					<Icon path={mdiMapMarkerPath} size={1} className="me-1" /> Missões
					{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				</div>
				<div className="d-flex gap-2 float-right ms-auto">
					<InputGroup style={{ minWidth: 300 }}>
						<InputGroup.Text className="bg-white">Status</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							className="fs-6 bg-white rounded"
							value={formStatus}
							options={formStatusOptions}
							onChange={(value) => {
								if (value) {
									setFormStatus(value);
								}
							}}
							styles={SelectGroupStyle}
						/>
					</InputGroup>
					<Button
						variant="air-blue"
						className="text-white"
						onClick={() => {
							navigate("/missaoFormulario");
						}}
					>
						Cadastrar
					</Button>
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading || queryHomepoint.isLoading ? (
					<>
						{Array.from({ length: Number(process.env.REACT_APP_SKELETON_COUNT) }, (_, index) => {
							return (
								<Col md={12} key={index}>
									<MissaoCard skeleton />
								</Col>
							);
						})}
					</>
				) : data?.Result !== 1 || queryHomepoint.data?.Result !== 1 ? (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							{data?.Message ?? queryHomepoint.data?.Message}
						</Alert>
					</Col>
				) : (
					<>
						{!data?.Data?.filter((item) => {
							return item.ativo === formStatus.value;
						}).length ? (
							<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
								<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
								Nenhum registro encontrado
							</Alert>
						) : (
							data?.Data?.filter((item) => {
								return item.ativo === formStatus.value;
							}).map((item, index) => {
								return (
									<Col md={12} key={index}>
										<MissaoCard data={item} homepointData={queryHomepoint.data?.Data} />
									</Col>
								);
							})
						)}
					</>
				)}
			</Row>
		</Layout>
	);
}

import { useNavigate } from "react-router-dom";
import { Col, Row } from "react-bootstrap";
import Icon from "@mdi/react";
import { mdiAccountMultipleOutline, mdiAccountTieOutline, mdiChevronRight, mdiDomainPlus, mdiOfficeBuilding } from "@mdi/js";

import Layout from "../../components/Layout";
import ListCard from "../../components/ListCard";

export default function Cadastros() {
	const navigate = useNavigate();

	function handleClick(item: string) {
		navigate(item);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiDomainPlus} size={1} className="me-1" /> Cadastros
			</h5>

			<Row className="mb-4">
				<Col md={12}>
					<ListCard
						onClick={() => {
							handleClick("/cadastrosEmpresas");
						}}
					>
						<ListCard.Icon>
							<Icon path={mdiOfficeBuilding} size={1} className="text-muted me-3" />
						</ListCard.Icon>
						<ListCard.Content>
							<ListCard.Item>
								<ListCard.Title>Empresas</ListCard.Title>
								<ListCard.Text>Lista de Empresas para gerenciar</ListCard.Text>
							</ListCard.Item>
						</ListCard.Content>
						<ListCard.Action>
							<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
						</ListCard.Action>
					</ListCard>
				</Col>
				<Col md={12}>
					<ListCard
						onClick={() => {
							handleClick("/cadastrosClientes");
						}}
					>
						<ListCard.Icon>
							<Icon path={mdiAccountMultipleOutline} size={1} className="text-muted me-3" />
						</ListCard.Icon>
						<ListCard.Content>
							<ListCard.Item>
								<ListCard.Title>Clientes</ListCard.Title>
								<ListCard.Text>Lista de Clientes para gerenciar</ListCard.Text>
							</ListCard.Item>
						</ListCard.Content>
						<ListCard.Action>
							<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
						</ListCard.Action>
					</ListCard>
				</Col>
				<Col md={12}>
					<ListCard
						onClick={() => {
							handleClick("/cadastrosAdministradores");
						}}
					>
						<ListCard.Icon>
							<Icon path={mdiAccountTieOutline} size={1} className="text-muted me-3" />
						</ListCard.Icon>
						<ListCard.Content>
							<ListCard.Item>
								<ListCard.Title>Administradores</ListCard.Title>
								<ListCard.Text>Lista de Administradores para gerenciar</ListCard.Text>
							</ListCard.Item>
						</ListCard.Content>
						<ListCard.Action>
							<Icon path={mdiChevronRight} size={1} className="text-muted m-2" />
						</ListCard.Action>
					</ListCard>
				</Col>
			</Row>
		</Layout>
	);
}

import { useEffect, useState } from "react";
import { Button, Card, Col, Form, InputGroup, Row, Spinner } from "react-bootstrap";
import { useMutation, useQuery } from "react-query";
import { Link, useNavigate, useParams } from "react-router-dom";
import GoogleMap, { Map } from "google-maps-react-markers";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiAlphaHCircleOutline, mdiChevronLeft, mdiMapMarkerPath, mdiTrashCanOutline } from "@mdi/js";

import { formatDistance } from "../../config/defines";
import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { queryClient } from "../../services/QueryClientService";
import { ApiResponseType } from "../../entities/ApiResponseEntity";
import { MissaoModel, MissaoWaypoint } from "../../models/MissaoModel";

import Layout from "../../components/Layout";
import Marker from "../../components/Marker";

const toastTitle = "Missão";

export default function MissaoFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { user, cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);

	const [formNome, setFormNome] = useState<string>();
	const [formCodigo, setFormCodigo] = useState<string>();
	const [formDescricao, setFormDescricao] = useState<string>();
	const [formAtivo, setFormAtivo] = useState(false);
	const [formWaypoints, setFormWaypoints] = useState<MissaoWaypoint[]>([]);
	const [formHomeLatitude, setFormHomeLatitude] = useState<number>();
	const [formHomeLongitude, setFormHomeLongitude] = useState<number>();
	const [formDistance, setFormDistance] = useState(0);
	const [formTime, setFormTime] = useState(0);
	const [formHomepoint, setFormHomepoint] = useState(false);
	const [formBaseLatitudeInicial, setFormBaseLatitudeInicial] = useState<number>();
	const [formBaseLongitudeInicial, setFormBaseLongitudeInicial] = useState<number>();
	const [formSOSLatitudeInicial, setFormSOSLatitudeInicial] = useState<number>();
	const [formSOSLongitudeInicial, setFormSOSLongitudeInicial] = useState<number>();
	const [formMap, setFormMap] = useState<Map>();
	const [formMapMarkers, setFormMapMarkers] = useState<google.maps.Marker[]>();

	const queryHomepoint = useQuery(["homepoint", cliente!.id], () => fetchDataHomepoint());
	const { isLoading, isFetching, isRefetching, refetch } = useQuery(["missao", id], () => fetchData(id), { enabled: id !== undefined });
	const queryHome = useQuery(["missao", id, "home"], () => fetchDataMissaoHome(id), { enabled: id !== undefined });

	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	useEffect(() => {
		formMapMarkers?.forEach((marker) => {
			marker.setDraggable(formStatus);
		});
	}, [formStatus, formMapMarkers]);

	useEffect(() => {
		if ((queryHome.isFetched || id === undefined) && queryHomepoint.isFetched) {
			if (queryHome.data?.Data) {
				setFormHomeLatitude(queryHome.data?.Data.latitude);
				setFormHomeLongitude(queryHome.data?.Data.longitude);
				return;
			}
			if (queryHomepoint.data?.Data?.homepoint) {
				setFormHomeLatitude(queryHomepoint.data?.Data?.homepoint?.latitude);
				setFormHomeLongitude(queryHomepoint.data?.Data?.homepoint?.longitude);
				return;
			}
		}
	}, [queryHomepoint, queryHome, id]);

	useEffect(() => {
		if (queryHomepoint.data?.Data?.homepoint) {
			const missao = new MissaoModel();
			missao.id = Number(id) ?? null;
			missao.nome = formNome!;
			missao.codigo = formCodigo!;
			missao.descricao = formDescricao!;
			missao.ativo = formAtivo ? 1 : 0;
			missao.dataTicks = 1;
			missao.waypoints = formWaypoints;

			if (queryHomepoint.data?.Data?.homepoint && user?.parametros) {
				let distance = missao.computeTotalDistance(queryHomepoint.data.Data.homepoint.latitude, queryHomepoint.data.Data.homepoint.longitude, user.parametros.alturaMinima);
				let time = distance / user.parametros.velocidade + (distance > 0 ? 60 : 0);

				setFormDistance(distance);
				setFormTime(time);
			}
		}
		// eslint-disable-next-line
	}, [formWaypoints, queryHomepoint.data?.Data]);

	useEffect(() => {
		let rota: google.maps.Polyline;
		let markers: google.maps.Marker[] = [];

		if (formMap) {
			rota = new google.maps.Polyline({
				path: formWaypoints
					.filter((_, index) => {
						return formHomepoint && index <= 1 ? false : true;
					})
					.map((waypoint) => {
						return { lat: waypoint.latitude, lng: waypoint.longitude };
					}),
				strokeColor: "#dc3545",
				strokeOpacity: 1,
				strokeWeight: 2,
			});
			rota.setMap(formMap);

			formWaypoints.forEach((waypoint, index) => {
				if (formHomepoint && index <= 1) {
					return;
				}
				let nome = waypoint.nome;
				let label = `${index + 1}`;
				let marker = new google.maps.Marker();

				marker.setPosition({ lat: waypoint.latitude, lng: waypoint.longitude });
				marker.setMap(formMap!);
				marker.setLabel(label);
				marker.setTitle(nome);
				marker.setDraggable(true);
				marker.addListener("dragend", (e: any) => {
					var lat = e.latLng.lat();
					var lng = e.latLng.lng();
					setFormWaypoints((p) => {
						return p.map((item, itemIndex) => {
							if (itemIndex === index) {
								item.latitude = lat;
								item.longitude = lng;
							}
							return item;
						});
					});
				});
				markers.push(marker);
			});

			if (markers.length >= 1) {
				var bounds = new google.maps.LatLngBounds();
				for (var i = 0; i < markers.length; i++) {
					if (markers[i].getVisible()) {
						let position = markers[i].getPosition();
						if (position) {
							bounds.extend(position);
						}
					}
				}
				formMap.fitBounds(bounds);
			}
		}
		setFormMapMarkers(markers);

		return () => {
			if (rota) {
				rota.setMap(null);
			}
			markers.forEach((marker) => {
				marker.setMap(null);
			});
		};
		// eslint-disable-next-line
	}, [formHomepoint, formWaypoints, formMap]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetCadastroMissao(id);
		if (resp.Result === 1 && resp.Data) {
			setFormNome(resp.Data.nome);
			setFormCodigo(resp.Data.codigo);
			setFormDescricao(resp.Data.descricao);
			setFormAtivo(resp.Data.ativo === 1 ? true : false);
			setFormWaypoints(resp.Data.waypoints);
			setFormHomepoint(resp.Data.waypoints.length >= 2 && resp.Data.waypoints[0].altura === 0 && resp.Data.waypoints[1].altura === 0);
			setFormBaseLatitudeInicial(resp.Data.waypoints[0].latitude);
			setFormBaseLongitudeInicial(resp.Data.waypoints[0].longitude);
			setFormSOSLatitudeInicial(resp.Data.waypoints[1].latitude);
			setFormSOSLongitudeInicial(resp.Data.waypoints[1].longitude);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	async function fetchDataHomepoint() {
		let respHome = await apiService.getHome(cliente!.id);
		let respSOS = await apiService.getSOS(cliente!.id);
		if (respHome.Result !== 1) {
			return { ...respHome, Data: undefined };
		}
		if (respSOS.Result !== 1) {
			return { ...respSOS, Data: undefined };
		}
		return {
			Result: 1,
			Message: undefined,
			Data: { homepoint: respHome.Data, sos: respSOS.Data },
		};
	}

	async function fetchDataMissaoHome(missaoId: any) {
		return await apiService.GetHomeMissao(missaoId);
	}

	function handleSubmit(e: any) {
		e.preventDefault();

		setFormSaving(true);

		const data = new MissaoModel();
		data.id = id ? Number(id) : -1;
		data.nome = formNome!;
		data.codigo = formCodigo!;
		data.descricao = formDescricao!;
		data.ativo = formAtivo ? 1 : 0;
		data.dataTicks = 1;
		data.waypoints = formWaypoints;
		if (formHomepoint) {
			data.waypoints[0].nome = "Homepoint";
			data.waypoints[0].altura = 0;
			data.waypoints[1].nome = "SOS";
			data.waypoints[1].altura = 0;
		}

		mutation.mutate(data);
	}

	async function mutateData(data: MissaoModel) {
		return await apiService.PostMissaoCadastro(data, `${data.id}`, cliente!.id);
	}

	function mutateSuccess(resp: ApiResponseType<number>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["missao", id]);
			handleToast(toastTitle, resp.Message, 5000);
			navigate("/missaoFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleMapLoad({ map }: { map: Map }) {
		map.addListener("click", (e: any) => {
			if (e.latLng) {
				var lat = e.latLng.lat();
				var lng = e.latLng.lng();
				setFormWaypoints((p) => {
					var waypoint = new MissaoWaypoint();
					waypoint.altura = user!.parametros?.alturaMinima ?? 1;
					waypoint.latitude = lat;
					waypoint.longitude = lng;
					waypoint.id = undefined;
					waypoint.nome = `Ponto ${p.length + 1}`;
					return [...p, waypoint];
				});
			}
		});

		setFormMap(map);
	}

	function handleRemover(index: number) {
		setFormWaypoints((p) =>
			p.filter((_, itemIndex) => {
				return itemIndex !== index;
			})
		);
	}

	function handleCompletarRota() {
		setFormWaypoints((p) => {
			let index = formHomepoint ? 2 : 0;
			var waypoint = new MissaoWaypoint();
			waypoint.altura = p[index].altura + 1;
			waypoint.latitude = p[index].latitude;
			waypoint.longitude = p[index].longitude;
			waypoint.id = undefined;
			waypoint.nome = `Ponto ${p.length + 1}`;
			return [...p, waypoint];
		});
	}

	function handleVoltar() {
		navigate("/missoes");
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Link to={"/missoes"} className="d-flex text-decoration-none">
					<Icon path={mdiChevronLeft} size={1} className="me-1" />
					<Icon path={mdiMapMarkerPath} size={1} className="me-1" />
					Formulário de Missão
				</Link>
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
			</h5>

			<Form onSubmit={handleSubmit}>
				<Card className="mb-3">
					<Card.Body>
						<Form.Group className="mb-3" controlId="nome">
							<Form.Label>Nome</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o nome"
								value={formNome}
								onChange={(event) => {
									setFormNome(event.target.value);
								}}
								required={true}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="codigo">
							<Form.Label>Código</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o código"
								value={formCodigo}
								onChange={(event) => {
									setFormCodigo(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="descricao">
							<Form.Label>Descrição</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui a descrição"
								value={formDescricao}
								onChange={(event) => {
									setFormDescricao(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="ativo">
							<Form.Label>Situação</Form.Label>
							<Form.Check
								type="switch"
								label={formAtivo ? "Ativo" : "Inativo"}
								className="d-flex gap-2"
								checked={formAtivo}
								onChange={(e) => {
									setFormAtivo(e.currentTarget.checked);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
					</Card.Body>
				</Card>

				<Card className="mb-3">
					<Card.Header className="d-flex align-items-center bg-white fs-5 fw-light p-3">
						Rota
						<div className="d-flex gap-3 float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
							<div>
								<span className="text-muted fw-lighter">Distância:</span> {formatDistance(formDistance).value.toFixed(1)}{" "}
								<span className="text-muted small">{formatDistance(formDistance).label}</span>
							</div>
							<div>
								<span className="text-muted fw-lighter">Tempo:</span>
								{moment.utc(formTime * 1000).format("HH:mm:ss")}
							</div>
							<div>
								<span className="text-muted fw-lighter">Pontos:</span> {formWaypoints.length}
							</div>
						</div>
					</Card.Header>
					<Card.Body className="pb-0">
						<div className="mb-3">
							<div className={`position-relative img-thumbnail`} style={{ height: 500, background: !formStatus ? "var(--bs-secondary-bg)" : "" }}>
								{formHomeLatitude && formHomeLongitude ? (
									<GoogleMap
										apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
										defaultZoom={14}
										defaultCenter={{ lat: formHomeLatitude, lng: formHomeLongitude }}
										options={{
											fullscreenControl: false,
											streetViewControl: false,
											zoomControlOptions: { position: 21 },
											mapTypeControlOptions: { position: 20 },
											mapTypeId: "hybrid",
										}}
										onGoogleApiLoaded={handleMapLoad}
										style={{ height: "100%" }}
									>
										{!formHomepoint && (
											<Marker lat={formHomeLatitude} lng={formHomeLongitude}>
												<Icon
													path={mdiAlphaHCircleOutline}
													size={1.5}
													className="text-danger bg-white rounded-circle"
													style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
												/>
											</Marker>
										)}
										{formHomepoint && (
											<Marker
												lat={formBaseLatitudeInicial}
												lng={formBaseLongitudeInicial}
												draggable={true}
												onDragEnd={(e: any, { latLng }: any) => {
													setFormWaypoints((p) => {
														return p.map((item, itemIndex) => {
															if (itemIndex === 0) {
																item.latitude = latLng.lat;
																item.longitude = latLng.lng;
															}
															return item;
														});
													});
												}}
											>
												<Icon
													path={mdiAlphaHCircleOutline}
													size={1.5}
													className="text-danger bg-white rounded-circle"
													style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
												/>
											</Marker>
										)}
										{formHomepoint && (
											<Marker
												lat={formSOSLatitudeInicial}
												lng={formSOSLongitudeInicial}
												draggable={true}
												onDragEnd={(e: any, { latLng }: any) => {
													setFormWaypoints((p) => {
														return p.map((item, itemIndex) => {
															if (itemIndex === 1) {
																item.latitude = latLng.lat;
																item.longitude = latLng.lng;
															}
															return item;
														});
													});
												}}
											>
												<Icon
													path={mdiAlphaHCircleOutline}
													size={1.5}
													className="text-air-blue bg-white rounded-circle"
													style={{ filter: "drop-shadow( 0 0 2px rgba(0, 0, 0, 0.5))" }}
												/>
											</Marker>
										)}
									</GoogleMap>
								) : (
									<div className="d-flex align-items-center justify-content-center" style={{ height: "100%" }}>
										<Spinner />
									</div>
								)}
							</div>

							<Form.Text className="d-flex align-items-center justify-content-between">
								Clique no local desejado no mapa para adicionar um novo ponto
								<div className="d-flex align-items-center">
									<Icon path={mdiAlphaHCircleOutline} size={1} className="text-danger ms-1" /> <span className="fw-bold">Homepoint</span>&nbsp;
									<Icon path={mdiAlphaHCircleOutline} size={1} className="text-air-blue ms-1" /> <span className="fw-bold">SOS</span>
								</div>
							</Form.Text>
						</div>
						{formWaypoints.map((waypoint, index) => {
							let disabled = false;
							let nome = waypoint.nome;
							let altura = waypoint.altura;
							if (formHomepoint && (index === 0 || index === 1)) {
								nome = index === 0 ? "Homepoint" : "SOS";
								altura = 0;
								disabled = true;
							}
							return (
								<Row key={index} className="mb-3">
									<Col lg="auto">
										<InputGroup>
											<InputGroup.Text>{index + 1}</InputGroup.Text>
										</InputGroup>
									</Col>
									<Col>
										<InputGroup>
											<InputGroup.Text>Nome</InputGroup.Text>
											<Form.Control
												placeholder="Informe o nome"
												value={nome}
												onChange={(e) => {
													setFormWaypoints((p) => {
														return p.map((item, itemIndex) => {
															if (itemIndex === index) {
																item.nome = e.currentTarget.value;
															}
															return item;
														});
													});
												}}
												disabled={!formStatus || disabled}
											/>
										</InputGroup>
									</Col>
									<Col>
										<InputGroup>
											<InputGroup.Text>Altura</InputGroup.Text>
											<Form.Control
												placeholder="Informe a altura"
												type="number"
												value={altura}
												onChange={(e) => {
													setFormWaypoints((p) => {
														return p.map((item, itemIndex) => {
															if (itemIndex === index) {
																item.altura = Number(e.currentTarget.value);
															}
															return item;
														});
													});
												}}
												disabled={!formStatus || disabled}
											/>
											<InputGroup.Text>m</InputGroup.Text>
										</InputGroup>
									</Col>
									<Col lg="auto">
										<Button
											variant="light"
											className={`me-2`}
											onClick={() => {
												handleRemover(index);
											}}
											disabled={!formStatus || disabled}
										>
											<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
										</Button>
									</Col>
								</Row>
							);
						})}

						{formWaypoints.length >= 2 && (
							<Form.Group className="mb-3" controlId="homepoint">
								<Form.Check
									type="switch"
									className="d-flex gap-2"
									checked={formHomepoint}
									onChange={(e) => {
										setFormHomepoint(e.currentTarget.checked);
										setFormBaseLatitudeInicial(formWaypoints[0].latitude);
										setFormBaseLongitudeInicial(formWaypoints[0].longitude);
										setFormSOSLatitudeInicial(formWaypoints[1].latitude);
										setFormSOSLongitudeInicial(formWaypoints[1].longitude);
									}}
									disabled={!formStatus}
									label={
										<div className="d-flex align-items-center justify-content-between">
											Utilizar primeiro ponto como
											<div className="d-flex align-items-center">
												<Icon path={mdiAlphaHCircleOutline} size={1} className="text-danger ms-1" /> <span className="fw-bold">Homepoint</span>&nbsp;
											</div>
											e segundo ponto como
											<div className="d-flex align-items-center">
												<Icon path={mdiAlphaHCircleOutline} size={1} className="text-air-blue ms-1" /> <span className="fw-bold">SOS</span>
											</div>
										</div>
									}
								/>
							</Form.Group>
						)}

						<div className="mb-3">
							<Button
								variant="light"
								onClick={handleCompletarRota}
								disabled={!formStatus || formWaypoints.length <= 1 || formWaypoints[formHomepoint ? 2 : 0].isEqualLatLng(formWaypoints.last())}
							>
								Completar Rota
							</Button>
						</div>
					</Card.Body>
				</Card>

				<div className="mb-4">
					{!formStatus ? (
						<Button
							className="me-2"
							variant="dark"
							type="button"
							onClick={(e) => {
								e.preventDefault();
								setFormStatus(true);
							}}
						>
							Editar Informações
						</Button>
					) : (
						<>
							<Button className="me-2 text-white" variant="air-blue" type="submit" disabled={formSaving}>
								{formSaving ? (
									<>
										<Spinner animation="border" size="sm" className="me-2" /> Salvando
									</>
								) : (
									"Salvar Informações"
								)}
							</Button>
							{id && (
								<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
									Cancelar
								</Button>
							)}
						</>
					)}
				</div>
			</Form>
		</Layout>
	);
}

export {};

declare global {
	export interface Array<T> {
		last(): T;
		first(): T;
		any(): boolean;
	}
}

if (!Array.prototype.last) {
	// eslint-disable-next-line
	Array.prototype.last = function <T>(): T | undefined {
		var arr: Array<T> = this;
		if (!arr.any()) return undefined;
		return arr[arr.length - 1];
	};
}

if (!Array.prototype.first) {
	// eslint-disable-next-line
	Array.prototype.first = function <T>(): T | undefined {
		var arr: Array<T> = this;
		if (!arr.any()) return undefined;
		return arr[0];
	};
}

if (!Array.prototype.any) {
	// eslint-disable-next-line
	Array.prototype.any = function (): boolean {
		return this.length > 0;
	};
}

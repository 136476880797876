import { useEffect, useRef, useState } from "react";
import { Button, Card, Form, Spinner, Image, ListGroup, Row, Col } from "react-bootstrap";
import { useNavigate, useParams } from "react-router-dom";
import { useMutation, useQuery } from "react-query";
import Icon from "@mdi/react";
import { mdiChevronLeft, mdiNewspaperVariantOutline, mdiTrashCanOutline } from "@mdi/js";
import { Editor } from "@tinymce/tinymce-react";
import Select from "react-select";
import moment from "moment";

import ApiService from "../../services/ApiService";
import { useToast } from "../../context/ToastContext";
import { useAuth } from "../../context/AuthContext";
import { queryClient } from "../../services/QueryClientService";
import { PostRelatorio } from "../../models/Relatorio";
import { ApiResponseType } from "../../entities/ApiResponseEntity";

import Layout from "../../components/Layout";
import { SelectStyle } from "../../config/select";

const toastTitle = "Relatório";

export default function RelatorioFormulario() {
	const navigate = useNavigate();
	const { id } = useParams();
	const { handleToast } = useToast();
	const { handleLogout } = useAuth();

	const apiService = new ApiService(handleLogout);

	const [formStatus, setFormStatus] = useState(id ? false : true);
	const [formSaving, setFormSaving] = useState(false);
	const [formRefetching, setFormRefetching] = useState(false);

	const [formBateriaId, setFormBateriaId] = useState<number>();
	const [formDroneId, setFormDroneId] = useState<number>();
	const [formTitulo, setFormTitulo] = useState("");
	const [formTipo, setFormTipo] = useState<{ label: string; value: number } | undefined>();
	const [formDescricao, setFormDescricao] = useState("");
	const [formNoticiaLoading, setFormNoticiaLoading] = useState(true);

	const [formArquivoLoading, setFormArquivoLoading] = useState(false);
	const [formArquivo, setFormArquivo] = useState("");
	const [formArquivoNome, setFormArquivoNome] = useState("");
	const [formArquivoDeleting, setFormArquivoDeleting] = useState(false);

	const noticiaRef = useRef<any>();

	const queryTipos = useQuery(["relatorios", "tipos"], () => fetchDataTipos());
	const { isLoading, isFetching, isRefetching, refetch } = useQuery<boolean>(["relatorio", id], () => fetchData(id), { enabled: id !== undefined });
	const queryArquivos = useQuery(["relatorio", id, "arquivos"], () => fetchDataArquivos(id));

	const mutation = useMutation(mutateData, { onSuccess: mutateSuccess });

	useEffect(() => {
		if (formRefetching) {
			refetch();
		}
		// eslint-disable-next-line
	}, [formRefetching]);

	async function fetchData(id: any) {
		if (formStatus && !formRefetching) {
			return false;
		}

		let resp = await apiService.GetRelatorio(id);
		if (resp.Result === 1 && resp.Data) {
			setFormBateriaId(resp.Data.bateria?.id);
			setFormDroneId(resp.Data.drone?.id);
			setFormTitulo(resp.Data.titulo);
			setFormTipo({ label: resp.Data.tipoRelatorio.nome, value: resp.Data.tipoRelatorio.id });
			setFormDescricao(resp.Data.descricao);
			if (noticiaRef) {
				noticiaRef.current.setContent(resp.Data.descricao);
			}
			// setFormCapaPreview(resp.Data.capa ? resp.Data.capa.capa : undefined);
		} else {
			if (resp.Result === 99) {
				handleLogout();
				navigate("/");
			}
			handleToast(toastTitle, resp.Message, 5000, "warning");
			handleVoltar();
		}

		setFormRefetching(false);

		return true;
	}

	async function fetchDataTipos() {
		return await apiService.GetTiposRelatorios();
	}

	async function fetchDataArquivos(relatorioId: any) {
		let resp = await apiService.GetArquivosRelatorio(relatorioId);
		setFormArquivoDeleting(false);
		return resp;
	}

	async function handleFormCapaChange(event: any) {
		let file = event.target.files[0];
		if (file) {
			setFormArquivoLoading(true);
			setFormArquivoNome(file.name);
			setFormArquivo(event.target.value);

			const formData = new FormData() as any;
			formData.append("file", file);
			let resp = await apiService.PostUploadArquivoRelatorio(`${id}`, formData);
			if (resp.Result === 1) {
				queryArquivos.refetch();
			} else {
				handleToast(toastTitle, resp.Message, 5000, "danger");
			}

			setFormArquivoLoading(false);
			setFormArquivo("");
		}
	}

	async function handleSave() {
		setFormSaving(true);

		const data: PostRelatorio = {
			id: id ?? null,
			bateriaId: formBateriaId ?? null,
			droneId: formDroneId ?? null,
			titulo: formTitulo,
			tipoRelatorioId: 1,
			descricao: noticiaRef.current.getContent(),
		};

		mutation.mutate(data);
	}

	async function mutateData(data: PostRelatorio) {
		return await apiService.PostRelatorio(data);
	}

	function mutateSuccess(resp: ApiResponseType<string>) {
		if (resp.Result === 1 && resp.Data) {
			queryClient.invalidateQueries(["relatorio", id]);
			handleToast(toastTitle, "Informações salvas com sucesso!", 5000);
			navigate("/relatorioFormulario/" + resp.Data);
		} else {
			handleToast(toastTitle, resp.Message, 5000, "danger");
		}
		handleCancel();
	}

	function handleVoltar() {
		navigate("/relatorios");
	}

	function handleCancel() {
		setFormRefetching(true);
		setFormStatus(false);
		setFormSaving(false);
	}

	async function handleDeletarArquivo(arquivoId: string) {
		setFormArquivoDeleting(true);
		await apiService.DeletarArquivoRelatorio(`${id}`, arquivoId);
		queryArquivos.refetch();
	}

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap" style={{ minHeight: 38 }}>
				<Icon path={mdiNewspaperVariantOutline} size={1} className="me-1" /> Formulário de Notícia
				{(isLoading || isFetching || isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
				<div className="float-right ms-auto" style={{ marginTop: -10, marginBottom: -10 }}>
					<Button className="d-flex" variant="dark" onClick={handleVoltar}>
						<Icon path={mdiChevronLeft} size={1} />
					</Button>
				</div>
			</h5>

			<Card className="mb-4">
				<Card.Body>
					<Form>
						<Form.Group className="mb-3" controlId="titulo">
							<Form.Label>Título</Form.Label>
							<Form.Control
								type="text"
								placeholder="Informe aqui o título"
								value={formTitulo}
								onChange={(event) => {
									setFormTitulo(event.target.value);
								}}
								disabled={!formStatus}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="tipo">
							<Form.Label>Tipo</Form.Label>
							<Select
								placeholder={"Selecione"}
								className="fs-6 bg-white rounded"
								value={formTipo}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={queryTipos.data?.Data?.map((tipo: any) => {
									return { label: tipo.nome, value: tipo.id };
								})}
								onChange={(value) => {
									if (value) setFormTipo(value);
								}}
								isDisabled={!formStatus}
								styles={SelectStyle}
							/>
						</Form.Group>
						<Form.Group className="mb-3" controlId="noticia">
							<Form.Label>Descrição</Form.Label>
							{formNoticiaLoading && (
								<div className={`form-control d-flex justify-content-center align-items-center ${!formStatus ? "disabled" : ""}`} style={{ height: 176 }}>
									<Spinner />
								</div>
							)}
							<div className={`${formNoticiaLoading ? "d-none" : ""}`}>
								<Editor
									tinymceScriptSrc={process.env.PUBLIC_URL + "/tinymce/tinymce.min.js"}
									onInit={(evt, editor) => {
										noticiaRef.current = editor;
										setFormNoticiaLoading(false);
									}}
									disabled={!formStatus}
									initialValue={formDescricao}
									init={{
										body_class: "bg-danger",
										placeholder: "Informe aqui a notícia",
										menubar: false,
										plugins: [
											"advlist",
											"autolink",
											"autoresize",
											"lists",
											"link",
											"image",
											"charmap",
											"anchor",
											"searchreplace",
											"visualblocks",
											"code",
											"fullscreen",
											"insertdatetime",
											"media",
											"table",
											"preview",
											"help",
											"wordcount",
										],
										toolbar_mode: "sliding",
										toolbar:
											"undo redo | bold italic underline strikethrough | fontfamily fontsize blocks | alignleft aligncenter alignright alignjustify | outdent indent |  numlist bullist | forecolor backcolor removeformat | pagebreak | charmap emoticons | fullscreen  preview save print | link anchor codesample | ltr rtl",
										content_style: "body { font-family:Helvetica,Arial,sans-serif; font-size:14px }",
										setup: (editor) => {
											editor.on("init", () => {
												editor.getContainer().style.transition = "border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out";
											});
											editor.on("focus", () => {
												editor.getContainer().classList.add("focus");
											});
											editor.on("blur", () => {
												editor.getContainer().classList.remove("focus");
											});
										},
										language: "pt_BR",
										branding: false,
									}}
								/>
							</div>
						</Form.Group>
						<Form.Group className="mb-3" controlId="arquivo">
							<Form.Label>
								Arquivos {(queryArquivos.isLoading || queryArquivos.isFetching || queryArquivos.isRefetching) && <Spinner size="sm" className="ms-1" variant="secondary" />}
							</Form.Label>
							<ListGroup className="mb-2 w-100">
								{queryArquivos.isLoading ? (
									<ListGroup.Item>
										<Row>
											<Col lg={1} className="d-flex justify-content-center align-items-center">
												<Button variant="light" className="skeleton skeleton-button" style={{ width: 45, height: 45 }} />
											</Col>
											<Col>
												<div className="skeleton skeleton-text skeleton-text-title">...</div>
												<div className="skeleton skeleton-text skeleton-text-content">...</div>
											</Col>
											<Col lg={1} className="d-flex justify-content-center align-items-center">
												<Button variant="light" className="skeleton skeleton-button" style={{ width: 50, height: 40 }} />
											</Col>
										</Row>
									</ListGroup.Item>
								) : (
									queryArquivos.data?.Data?.map((arquivo) => {
										return (
											<ListGroup.Item action href={arquivo.url} target="_blank">
												<Row>
													<Col lg={1} className="d-flex justify-content-center align-items-center">
														<Image src={arquivo.thumbnail} style={{ maxWidth: 45, maxHeight: 45 }} />
													</Col>
													<Col>
														{arquivo.nome}
														<div className="text-muted small">{arquivo.data.dateString}</div>
													</Col>
													<Col lg={1} className="d-flex justify-content-center align-items-center">
														<Button
															variant="light"
															onClick={(e) => {
																e.preventDefault();
																e.stopPropagation();
																handleDeletarArquivo(arquivo.id);
															}}
															disabled={formArquivoDeleting}
														>
															<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
														</Button>
													</Col>
												</Row>
											</ListGroup.Item>
										);
									})
								)}
								{formArquivoLoading && (
									<ListGroup.Item>
										<Row>
											<Col lg={1} className="d-flex justify-content-center align-items-center">
												<Spinner />
											</Col>
											<Col>
												{formArquivoNome}
												<div className="text-muted small">{moment().format("DD/MM/YYYY HH:mm")}</div>
											</Col>
											<Col lg={1} className="d-flex justify-content-center align-items-center">
												<Spinner />
											</Col>
										</Row>
									</ListGroup.Item>
								)}
							</ListGroup>
							<Form.Control type="file" placeholder="arquivo" value={formArquivo} onChange={handleFormCapaChange} disabled={!formStatus || formArquivoLoading} />
						</Form.Group>

						{!formStatus ? (
							<Button
								className="me-2"
								variant="dark"
								type="button"
								onClick={() => {
									setFormStatus(true);
								}}
							>
								Editar Informações
							</Button>
						) : (
							<>
								<Button className="me-2" variant="air-blue" type="button" onClick={handleSave} disabled={formSaving}>
									{formSaving ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Salvando
										</>
									) : (
										"Salvar Informações"
									)}
								</Button>
								{id && (
									<Button className="me-2" variant="light" type="button" onClick={handleCancel} disabled={formSaving}>
										Cancelar
									</Button>
								)}
							</>
						)}
					</Form>
				</Card.Body>
			</Card>
		</Layout>
	);
}

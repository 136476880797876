export const SelectStyle = {
	container: (styles: any, state: any) => {
		return {
			...styles,
			flex: 1,
		};
	},
	control: (styles: any, state: any) => {
		return {
			...styles,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: state.isFocused ? "#86b7fe" : "#ced4da",
			boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(13 110 253 / 25%)" : "none",
			borderRadius: "0.375rem",
			backgroundColor: state.isDisabled ? "#e9ecef" : "",
		};
	},
	singleValue: (styles: any, state: any) => {
		return {
			...styles,
			color: "#212529",
		};
	},
};

export const SelectGroupStyle = {
	container: (styles: any, state: any) => {
		return {
			...styles,
			flex: 1,
		};
	},
	control: (styles: any, state: any) => {
		return {
			...styles,
			borderWidth: 1,
			borderStyle: "solid",
			borderColor: state.isFocused ? "#86b7fe" : "#ced4da",
			boxShadow: state.isFocused ? "0 0 0 0.25rem rgb(13 110 253 / 25%)" : "none",
			borderRadius: "0.375rem",
			borderTopLeftRadius: 0,
			borderBottomLeftRadius: 0,
			backgroundColor: state.isDisabled ? "#e9ecef" : "",
		};
	},
	singleValue: (styles: any, state: any) => {
		return {
			...styles,
			color: "#212529",
		};
	},
	menu: (styles: any, state: any) => {
		return {
			...styles,
			minWidth: 200,
		};
	},
};

import { useNavigate } from "react-router-dom";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiChevronRight } from "@mdi/js";

import { MissaoLogModel } from "../../models/MissaoLogModel";

import ListCard from "../ListCard";
import { formatDistance } from "../../config/defines";

type ClienteVooCardPropsContent = {
	data: MissaoLogModel;
	drone?: boolean;
	skeleton?: never;
};

type ClienteVooCardPropsSkeleton = {
	data?: never;
	drone?: never;
	skeleton: true;
};

type ClienteVooCardProps = ClienteVooCardPropsContent | ClienteVooCardPropsSkeleton;

export default function ClienteVooCard(props: ClienteVooCardProps) {
	const navigate = useNavigate();

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/voo/" + props.data.id);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Data</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.dataString ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Missão</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.missao?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Piloto</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.piloto?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					{props.drone && (
						<ListCard.Item>
							<ListCard.Title skeleton={props.skeleton}>Drone</ListCard.Title>
							<ListCard.Text skeleton={props.skeleton}>{props.data?.drone?.nome ?? "..."}</ListCard.Text>
						</ListCard.Item>
					)}
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Tempo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{moment.utc(Number(props.data?.tempo) * 1000).format("HH:mm:ss") ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Distância</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>
							{props.data?.distancia ? (
								<>
									{formatDistance(props.data?.distancia).value.toFixed(1)} <span className="text-muted small">{formatDistance(props.data?.distancia).label}</span>
								</>
							) : (
								"..."
							)}
						</ListCard.Text>
					</ListCard.Item>
					{/* <ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Bateria</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>
							{props.data?.bateria} <span className="text-muted small">%</span>
						</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Vento</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>
							{props.data?.vento.toFixed(1)} <span className="text-muted small">km/h</span>
						</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Temperatura</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>
							{props.data?.temperatura.toFixed(1)} <span className="text-muted small">°C</span>
						</ListCard.Text>
					</ListCard.Item> */}
				</ListCard.Content>
				<ListCard.Action>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>
		</>
	);
}

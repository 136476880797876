import { Dropdown, Image, Nav, Navbar } from "react-bootstrap";
import { useNavigate } from "react-router-dom";
import Icon from "@mdi/react";
import { mdiDomainPlus } from "@mdi/js";

import { useAuth } from "../../context/AuthContext";
import { screens } from "../../config/screens";

export default function Sidebar() {
	const { user, cliente, handleLogout, routeActive } = useAuth();
	const navigate = useNavigate();

	const handleLink = async (url: string) => {
		navigate(url);
	};

	async function handleSair() {
		handleLogout();
		navigate("/");
	}

	return (
		<Navbar variant="dark" bg="dark" expand="md" id="sidebar" className="d-block d-md-flex flex-column d-sm-block flex-shrink-0 p-3 col-md-3 col-lg-3 col-xl-2 sticky-top shadow">
			<Navbar.Brand href="#" onClick={() => handleLink("/dashboard")} style={{ marginRight: 0 }}>
				<div className="d-inline-flex gap-1 align-items-center">
					{process.env.REACT_APP_TENANT === "BMRS" && <Image fluid src={process.env.PUBLIC_URL + "/bm.png"} className="mb-3" style={{ maxHeight: 64 }} />}
					<Image fluid src={process.env.PUBLIC_URL + "/logo_vigilante.png"} className="mb-3" style={{ maxHeight: 64 }} />
				</div>
			</Navbar.Brand>
			<Navbar.Toggle aria-controls="basic-navbar-nav" className="float-end" />
			<Navbar.Collapse id="basic-navbar-nav" className="w-100">
				<Nav className="flex-column mb-auto w-100 h-100">
					{cliente &&
						screens
							.filter((screen) => {
								return (
									(screen.userTipos === undefined || screen.userTipos[user!.tipo] === true || screen.userTipos[user!.tipo] === undefined) &&
									(user!.permissoes.some((permissao) => {
										return screen.permissoes?.includes(permissao.codigo);
									}) ||
										screen.permissoes === undefined) &&
									screen.showSidebar
								);
							})
							.map((screen, screenIndex) => {
								return (
									<Nav.Item key={screenIndex}>
										<Nav.Link
											className="d-flex"
											active={routeActive === screen.route}
											onClick={() => handleLink(screen.route)}
											onDoubleClick={() => {
												if (screen.routeSecret && (user!.canCreateAdmin() || user!.canCreateCliente() || user!.canCreateEmpresa())) {
													handleLink(screen.routeSecret);
												}
											}}
										>
											<Icon path={screen.icon} size={1} className="me-2" /> {screen.name}
										</Nav.Link>
									</Nav.Item>
								);
							})}

					{(user!.canCreateAdmin() || user!.canCreateCliente() || user!.canCreateEmpresa()) && (
						<Nav.Item>
							<Nav.Link className="d-flex" active={routeActive === "/cadastros"} onClick={() => handleLink("/cadastros")}>
								<Icon path={mdiDomainPlus} size={1} className="me-2" /> Cadastros
							</Nav.Link>
						</Nav.Item>
					)}

					<Nav.Item className="mt-auto">
						<hr />
					</Nav.Item>

					<Nav.Item>
						<Dropdown drop="up">
							<Dropdown.Toggle variant="dark-blue" className="d-flex justify-content-between align-items-center w-100">
								{user?.nome}
							</Dropdown.Toggle>
							<Dropdown.Menu className="shadow">
								<Dropdown.Item href="#" onClick={handleSair}>
									Sair
								</Dropdown.Item>
							</Dropdown.Menu>
						</Dropdown>
					</Nav.Item>
				</Nav>
			</Navbar.Collapse>
		</Navbar>
	);
}

import { Navigate, useLocation } from 'react-router-dom';

import { useAuth } from '../../context/AuthContext';

export default function RouteAuth({ children }: { children: JSX.Element }) {

	const { user } = useAuth();
	const location = useLocation();
    
    if (!user) {
        return <Navigate to="/" state={{ from: location }} replace />;
    }

    return children;
}
import React from "react";

interface IconSVGProps extends React.HTMLProps<SVGSVGElement>{
    path: React.FunctionComponent<React.SVGProps<SVGSVGElement> & { title?: string | undefined; }>;
    crossOrigin?: '' | 'anonymous' | 'use-credentials' | undefined;
}

export default function IconSVG({path, ...rest} : IconSVGProps){
    
    const Icon = path;

    return (
        <Icon {...rest}/>
    );
}
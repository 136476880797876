import { useEffect, useRef, useState } from "react";
import { Alert, Button, Card, Col, Container, FloatingLabel, Form, Image, InputGroup, Row, Spinner } from "react-bootstrap";
import { Navigate, useLocation, useNavigate } from "react-router-dom";

import { useAuth } from "../../context/AuthContext";
import { useToast } from "../../context/ToastContext";
import ApiService from "../../services/ApiService";
import { LoginModel, LoginPostData } from "../../models/LoginModel";
import Icon from "@mdi/react";
import { mdiEye, mdiEyeOff } from "@mdi/js";
import moment from "moment";

export default function Login() {
	const { user, handleLogin, handleLogout } = useAuth();
	const { clearToast } = useToast();

	const location = useLocation();
	const navigate = useNavigate();

	const emailRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;
	const passwordRef = useRef<HTMLInputElement>() as React.RefObject<HTMLInputElement>;

	const [loading, setLoading] = useState(false);
	const [message, setMessage] = useState("");
	const [senhaExibir, setSenhaExibir] = useState(false);

	const apiService = new ApiService(handleLogout);

	useEffect(() => {
		clearToast();
		// eslint-disable-next-line
	}, []);

	async function handleSubmit(event: React.FormEvent<HTMLFormElement>) {
		event.preventDefault();

		if (!emailRef.current || !passwordRef.current) {
			return false;
		}

		setLoading(true);
		setMessage("");

		var loginPostData: LoginPostData = {
			appVersion: "1.0",
			deviceToken: "",
			deviceType: 3,
			osVersion: "",
			usuario: emailRef.current.value,
			senha: passwordRef.current.value,
		};

		let response = await apiService.doLogin(loginPostData);
		if (response.Result === 1 && response.Data) {
			let user = new LoginModel(response.Data);
			handleLogin(user);
			navigate("/resumo");
		} else {
			setLoading(false);
			setMessage(response.Message);
		}
	}

	if (user) {
		return <Navigate to="/dashboard" state={{ from: location }} replace />;
	}

	return (
		<Container fluid className="position-relative" style={{ background: "rgba(0,0,0,0.6)" }}>
			<div
				className="position-absolute"
				style={{
					inset: 0,
					height: "100%",
					backgroundImage: `url(${process.env.PUBLIC_URL}/login_background.webp)`,
					backgroundSize: "cover",
					zIndex: -1,
				}}
			/>
			<Row className="align-items-center" style={{ minHeight: "100vh" }}>
				<Col className="d-flex justify-content-center align-items-center mx-auto pt-4" style={{ maxWidth: 500 }}>
					<Card className="w-100 p-4 shadow border-0" style={{ backgroundColor: "rgba(31, 37, 60,0.8)", backdropFilter: "blur(5px)" }}>
						<Card.Body className="d-flex flex-column align-items-center">
							{process.env.REACT_APP_TENANT === "BMRS" && <Image fluid className="mb-0" src={process.env.PUBLIC_URL + "/bm.png"} style={{ height: 150 }} />}
							<Image fluid className="mb-4" src={process.env.PUBLIC_URL + "/logo_vigilante.png"} style={{ height: 150 }} />

							<Form onSubmit={handleSubmit} className="w-100">
								<FloatingLabel controlId="usuario" label="Usuário" className="mb-3">
									<Form.Control type="text" placeholder="Informe aqui o usuário" ref={emailRef} required />
								</FloatingLabel>

								<InputGroup className="mb-3">
									<FloatingLabel controlId="senha" label="Senha">
										<Form.Control type={senhaExibir ? "text" : "password"} placeholder="Informe aqui a senha" ref={passwordRef} required />
									</FloatingLabel>
									<Button
										variant="light"
										className="border"
										onClick={() => {
											setSenhaExibir(!senhaExibir);
										}}
									>
										<Icon path={senhaExibir ? mdiEye : mdiEyeOff} size={1} />
									</Button>
								</InputGroup>

								{message && (
									<Alert variant="danger" className="text-center">
										{message}
									</Alert>
								)}

								<Button type="submit" variant="air-blue" size="lg" className="w-100 text-white" disabled={loading}>
									{loading ? (
										<>
											<Spinner animation="border" size="sm" className="me-2" /> Entrando
										</>
									) : (
										"Entrar"
									)}
								</Button>
							</Form>
						</Card.Body>
					</Card>
				</Col>
				<Col lg={12}>
					<div className="d-flex justify-content-center gap-1 p-3 text-white">
						Um produto
						<a href="https://airrobotics.com.br/" target="_blank" rel="noreferrer" className="d-flex align-items-center text-decoration-none text-white gap-1">
							<Image src={process.env.PUBLIC_URL + "/logo_icone.png"} style={{ height: 24 }} /> AirRobotics
						</a>{" "}
						Copyright © {moment().format("YYYY")}. Todos os direitos reservados.
					</div>
				</Col>
			</Row>
		</Container>
	);
}

import { useEffect, useState } from "react";
import { Alert, Button, Col, InputGroup, Row, Spinner } from "react-bootstrap";
import { useQuery } from "react-query";
import moment, { Moment } from "moment";
import ReactDatePicker from "react-datepicker";
import Select from "react-select";
import Icon from "@mdi/react";
import { mdiAirplane, mdiBroom, mdiCardRemoveOutline, mdiChevronRight } from "@mdi/js";

import { SelectGroupStyle } from "../../config/select";
import ApiService from "../../services/ApiService";
import { useAuth } from "../../context/AuthContext";

import ClienteVooCard from "../../components/ClienteVooCard";
import Layout from "../../components/Layout";
import ListCard from "../../components/ListCard";
import { formatDistance } from "../../config/defines";

export default function Voos() {
	const { user, cliente, handleLogout } = useAuth();
	const apiService = new ApiService(handleLogout);

	const queryMissoes = useQuery(["missoes", cliente!.id], () => fetchDataMissoes());
	const queryPilotos = useQuery(["pilotos", cliente!.id], () => fetchDataPilotos(), { enabled: user!.tipo !== 1 });
	const queryDrones = useQuery(["drones", cliente!.id], () => fetchDataDrones());

	const [filtroMissao, setFiltroMissao] = useState<{ label: string; value: number | undefined } | undefined>({ label: "Todos", value: undefined });
	const [filtroPiloto, setFiltroPiloto] = useState<{ label: string; value: number | undefined } | undefined>({ label: "Todos", value: undefined });
	const [filtroDrone, setFiltroDrone] = useState<{ label: string; value: number | undefined } | undefined>({ label: "Todos", value: undefined });
	const [filtroDataInicial, setFiltroDataInicial] = useState<Moment | null>(moment().subtract(30, "days"));
	const [filtroDataFinal, setFiltroDataFinal] = useState<Moment | null>(moment().hours(23).minutes(59).seconds(59));

	const { data, isLoading, isRefetching, refetch } = useQuery(["cliente", "voos", filtroMissao?.value, filtroPiloto?.value, filtroDrone?.value], () => fetchData(), {
		enabled: !!filtroDrone,
	});

	useEffect(() => {
		if (!!filtroDrone && !!filtroDataInicial && !!filtroDataFinal) {
			refetch();
		}
		// eslint-disable-next-line
	}, [filtroDataInicial, filtroDataFinal]);

	useEffect(() => {
		let missao = sessionStorage.getItem("voosMissao");
		if (missao) {
			setFiltroMissao(JSON.parse(missao));
		}
		let piloto = sessionStorage.getItem("voosPiloto");
		if (piloto) {
			setFiltroPiloto(JSON.parse(piloto));
		}
		let drone = sessionStorage.getItem("voosDrone");
		if (drone) {
			setFiltroDrone(JSON.parse(drone));
		}
		let voosDataInicial = sessionStorage.getItem("voosDataInicial");
		if (voosDataInicial && voosDataInicial !== "") {
			setFiltroDataInicial(moment(Number(voosDataInicial)));
		}
		let voosDataFinal = sessionStorage.getItem("voosDataFinal");
		if (voosDataFinal && voosDataFinal !== "") {
			setFiltroDataFinal(moment(Number(voosDataFinal)));
		}
	}, []);

	async function fetchData() {
		return await apiService.GetLogsMissao(cliente!.id, filtroMissao!.value, filtroPiloto!.value, filtroDrone!.value, filtroDataInicial!.valueOf(), filtroDataFinal!.valueOf());
	}

	async function fetchDataMissoes() {
		return await apiService.GetMissoesCliente(cliente!.id);
	}

	async function fetchDataPilotos() {
		return await apiService.GetPilotosClienteCadastro(cliente!.id);
	}

	async function fetchDataDrones() {
		return await apiService.GetDronesCliente(cliente!.id);
	}

	function handleFiltroLimpar() {
		setFiltroMissao({ label: "Todos", value: undefined });
		setFiltroPiloto({ label: "Todos", value: undefined });
		setFiltroDrone({ label: "Todos", value: undefined });
		setFiltroDataInicial(moment().subtract(30, "days"));
		setFiltroDataFinal(moment().hours(23).minutes(59).seconds(59));
		sessionStorage.setItem("voosPiloto", JSON.stringify({ label: "Todos", value: undefined }));
		sessionStorage.setItem("voosMissao", JSON.stringify({ label: "Todos", value: undefined }));
		sessionStorage.setItem("voosDrone", JSON.stringify({ label: "Todos", value: undefined }));
		sessionStorage.setItem("voosDataInicial", moment().subtract(30, "days").valueOf().toString());
		sessionStorage.setItem("voosDataFinal", moment().hours(23).minutes(59).seconds(59).valueOf().toString());
	}

	let contadores = {
		registros: 0,
		tempo: 0,
		distancia: 0,
		bateria: 0,
		vento: 0,
		temperatura: 0,
		bateriaMaior: 0,
		ventoMaior: 0,
		temperaturaMaior: 0,
	};

	return (
		<Layout>
			<h5 className="mt-3 mb-3 d-flex align-items-center fw-light flex-wrap gap-2" style={{ minHeight: 38 }}>
				<div className="d-flex align-items-center">
					<Icon path={mdiAirplane} size={1} className="me-1" /> Voos
					{isRefetching && <Spinner size="sm" className="ms-1" variant="secondary" />}
				</div>
				<div className="float-right ms-auto d-flex gap-1 align-items-center flex-wrap">
					<InputGroup className="fs-6" style={{ width: 200 }}>
						<InputGroup.Text className="bg-white px-2">
							<span className="small">Missão</span>
						</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							className="small bg-white rounded"
							value={filtroMissao}
							noOptionsMessage={() => {
								return "Nenhuma opção";
							}}
							options={[
								{ label: "Todos", value: undefined },
								{
									label: "Ativo",
									options:
										queryMissoes.data?.Data?.filter((missao) => {
											return missao.ativo === 1;
										}).map((missao) => {
											return { label: missao.nome, value: missao.id };
										}) ?? [],
								},
								{
									label: "Inativo",
									options:
										queryMissoes.data?.Data?.filter((missao) => {
											return missao.ativo === 0;
										}).map((missao) => {
											return { label: missao.nome, value: missao.id };
										}) ?? [],
								},
							]}
							onChange={(value) => {
								setFiltroMissao(value ?? undefined);
								sessionStorage.setItem("voosMissao", JSON.stringify(value));
							}}
							styles={SelectGroupStyle}
						/>
					</InputGroup>
					{user!.tipo !== 1 && (
						<InputGroup className="fs-6" style={{ width: 200 }}>
							<InputGroup.Text className="bg-white px-2">
								<span className="small">Piloto</span>
							</InputGroup.Text>
							<Select
								placeholder={"Selecione"}
								className="small bg-white rounded"
								value={filtroPiloto}
								noOptionsMessage={() => {
									return "Nenhuma opção";
								}}
								options={[
									{ label: "Todos", value: undefined },
									...(queryPilotos.data?.Data?.map((piloto) => {
										return { label: piloto.nome, value: piloto.id };
									}) ?? []),
								]}
								onChange={(value) => {
									setFiltroPiloto(value ?? undefined);
									sessionStorage.setItem("voosPiloto", JSON.stringify(value));
								}}
								styles={SelectGroupStyle}
							/>
						</InputGroup>
					)}
					<InputGroup className="fs-6" style={{ width: 200 }}>
						<InputGroup.Text className="bg-white px-2">
							<span className="small">Drone</span>
						</InputGroup.Text>
						<Select
							placeholder={"Selecione"}
							className="small bg-white rounded"
							value={filtroDrone}
							noOptionsMessage={() => {
								return "Nenhuma opção";
							}}
							options={[
								{ label: "Todos", value: undefined },
								...(queryDrones.data?.Data?.map((drone) => {
									return { label: drone.nome, value: drone.id };
								}) ?? []),
							]}
							onChange={(value) => {
								setFiltroDrone(value ?? undefined);
								sessionStorage.setItem("voosDrone", JSON.stringify(value));
							}}
							styles={SelectGroupStyle}
						/>
					</InputGroup>
					<InputGroup className="fs-6" style={{ width: 260 }}>
						<InputGroup.Text className="bg-white px-2">
							<span className="small">Período</span>
						</InputGroup.Text>
						<ReactDatePicker
							placeholderText="Data inicial e final"
							className="form-control small"
							calendarClassName="shadow-lg"
							onChange={(datas) => {
								setFiltroDataInicial(datas[0] ? moment(datas[0]) : null);
								setFiltroDataFinal(datas[1] ? moment(datas[1]).hours(23).minutes(59).seconds(59) : null);
								sessionStorage.setItem("voosDataInicial", datas[0] ? moment(datas[0]).valueOf().toString() : "");
								sessionStorage.setItem("voosDataFinal", datas[1] ? moment(datas[1]).hours(23).minutes(59).seconds(59).valueOf().toString() : "");
							}}
							startDate={filtroDataInicial?.toDate()}
							endDate={filtroDataFinal?.toDate()}
							maxDate={moment().toDate()}
							monthsShown={2}
							dateFormat="dd/MM/yyyy"
							selectsRange={true}
						/>
					</InputGroup>
					<Button variant="dark" size="sm" onClick={handleFiltroLimpar}>
						<Icon path={mdiBroom} size={1} />
					</Button>
				</div>
			</h5>

			<Row className="mb-4">
				{isLoading || queryDrones.isLoading || !queryDrones.isFetched ? (
					<>
						{Array.from({ length: Number(process.env.REACT_APP_SKELETON_COUNT) }, (_, index) => {
							return (
								<Col md={12} key={index}>
									<ClienteVooCard skeleton />
								</Col>
							);
						})}
					</>
				) : data?.Result !== 1 || queryDrones.data?.Result !== 1 ? (
					<Col md={12}>
						<Alert variant="secondary" className="text-center">
							{data?.Message ?? "Nenhum registro encontrado"}
						</Alert>
					</Col>
				) : (
					<>
						{!data?.Data?.length ? (
							<Alert variant="light" className="text-center p-4 d-flex justify-content-center align-items-center">
								<Icon path={mdiCardRemoveOutline} size={1} className="me-2" />
								Nenhum registro encontrado
							</Alert>
						) : (
							<>
								{data?.Data?.map((item, index) => {
									contadores = {
										registros: contadores.registros + 1,
										tempo: contadores.tempo + item.tempo,
										distancia: contadores.distancia + item.distancia,
										bateria: contadores.bateria + item.bateria,
										vento: contadores.vento + item.vento,
										temperatura: contadores.temperatura + item.temperatura,
										bateriaMaior: contadores.bateriaMaior > item.bateria ? contadores.bateriaMaior : item.bateria,
										ventoMaior: contadores.ventoMaior > item.vento ? contadores.ventoMaior : item.vento,
										temperaturaMaior: contadores.temperaturaMaior > item.temperatura ? contadores.temperaturaMaior : item.temperatura,
									};
									return (
										<Col md={12} key={index}>
											<ClienteVooCard data={item} drone={filtroDrone?.value === undefined} />
										</Col>
									);
								})}
								<Col md={12}>
									<ListCard className="bg-light border-white shadow-sm">
										<ListCard.Content>
											<ListCard.Item>
												<ListCard.Text>Média</ListCard.Text>
												<ListCard.Text>Total</ListCard.Text>
											</ListCard.Item>
											<ListCard.Item>
												<ListCard.Title>&nbsp;</ListCard.Title>
												<ListCard.Text>&nbsp;</ListCard.Text>
											</ListCard.Item>
											<ListCard.Item>
												<ListCard.Title>&nbsp;</ListCard.Title>
												<ListCard.Text>&nbsp;</ListCard.Text>
											</ListCard.Item>
											{filtroDrone?.value === undefined && (
												<ListCard.Item>
													<ListCard.Title>&nbsp;</ListCard.Title>
													<ListCard.Text>&nbsp;</ListCard.Text>
												</ListCard.Item>
											)}
											<ListCard.Item>
												<ListCard.Text>{moment.utc(Number(contadores.tempo / contadores.registros) * 1000).format("HH:mm:ss")}</ListCard.Text>
												<ListCard.Text>{moment.utc(Number(contadores.tempo) * 1000).format("HH:mm:ss")}</ListCard.Text>
											</ListCard.Item>
											<ListCard.Item>
												<ListCard.Text>
													{formatDistance(contadores.distancia / contadores.registros).value.toFixed(1)}{" "}
													<span className="text-muted small">{formatDistance(contadores.distancia / contadores.registros).label}</span>
												</ListCard.Text>
												<ListCard.Text>
													{formatDistance(contadores.distancia).value.toFixed(1)} <span className="text-muted small">{formatDistance(contadores.distancia).label}</span>
												</ListCard.Text>
											</ListCard.Item>
										</ListCard.Content>
										<ListCard.Action>
											<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 invisible`} />
										</ListCard.Action>
									</ListCard>
								</Col>
							</>
						)}
					</>
				)}
			</Row>
		</Layout>
	);
}

import { BrowserRouter, Route, Routes } from "react-router-dom";
import { registerLocale, setDefaultLocale } from "react-datepicker";
import ptBR from "date-fns/locale/pt-BR";

import "./utils/ArrayAdds";
import "./utils/Strings";
import "./App.scss";

import AuthProvider from "./context/AuthContext";
import ToastProvider from "./context/ToastContext";
import ScrollToTop from "./components/ScrollToTop";
import RouteAuth from "./components/RouteAuth";

import Login from "./screens/Login";
import Resumo from "./screens/Resumo";
import Lives from "./screens/Lives";
import Live from "./screens/Live";
import Dashboard from "./screens/Dashboard";
import Voos from "./screens/Voos";
import Voo from "./screens/Voo";
import Status from "./screens/Status";
import Pilotos from "./screens/Pilotos";
import PilotoFormulario from "./screens/PilotoFormulario";
import Drones from "./screens/Drones";
import DroneFormulario from "./screens/DroneFormulario";
import Missoes from "./screens/Missoes";
import MissaoFormulario from "./screens/MissaoFormulario";
import Logs from "./screens/Logs";
import Log from "./screens/Log";
import Baterias from "./screens/Baterias";
import BateriaFormulario from "./screens/BateriaFormulario";
import Relatorios from "./screens/Relatorios";
import RelatorioFormulario from "./screens/RelatorioFormulario";

import ConfiguracoesFormulario from "./screens/ConfiguracoesFormulario";
import ConfiguracoesEmpresaFormulario from "./screens/ConfiguracoesEmpresaFormulario";
import HomepointSOSFormulario from "./screens/HomepointSOSFormulario";
import Cadastros from "./screens/Cadastros";
import CadastrosEmpresas from "./screens/CadastrosEmpresas";
import CadastroEmpresaFormulario from "./screens/CadastroEmpresaFormulario";
import CadastrosClientes from "./screens/CadastrosClientes";
import CadastroClienteFormulario from "./screens/CadastroClienteFormulario";
import CadastrosAdministradores from "./screens/CadastrosAdministradores";
import CadastroAdministradorFormulario from "./screens/CadastroAdministradorFormulario";

registerLocale("ptBR", ptBR);
setDefaultLocale("ptBR");

function App() {
	return (
		<BrowserRouter basename={process.env.PUBLIC_URL}>
			<ScrollToTop />
			<AuthProvider>
				<ToastProvider>
					<Routes>
						<Route path="/" element={<Login />} />
						<Route
							path="/resumo"
							element={
								<RouteAuth>
									<Resumo />
								</RouteAuth>
							}
						/>
						<Route
							path="/dashboard"
							element={
								<RouteAuth>
									<Dashboard />
								</RouteAuth>
							}
						/>
						<Route
							path="/lives"
							element={
								<RouteAuth>
									<Lives />
								</RouteAuth>
							}
						/>
						<Route
							path="/live/:vooId"
							element={
								<RouteAuth>
									<Live />
								</RouteAuth>
							}
						/>
						<Route
							path="/voos"
							element={
								<RouteAuth>
									<Voos />
								</RouteAuth>
							}
						/>
						<Route
							path="/voo/:missaoLogID"
							element={
								<RouteAuth>
									<Voo />
								</RouteAuth>
							}
						/>
						<Route
							path="/status"
							element={
								<RouteAuth>
									<Status />
								</RouteAuth>
							}
						/>
						<Route
							path="/pilotos"
							element={
								<RouteAuth>
									<Pilotos />
								</RouteAuth>
							}
						/>
						<Route
							path="/pilotoFormulario"
							element={
								<RouteAuth>
									<PilotoFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<PilotoFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<PilotoFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/drones"
							element={
								<RouteAuth>
									<Drones />
								</RouteAuth>
							}
						/>
						<Route
							path="/droneFormulario"
							element={
								<RouteAuth>
									<DroneFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<DroneFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<DroneFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/missoes"
							element={
								<RouteAuth>
									<Missoes />
								</RouteAuth>
							}
						/>
						<Route
							path="/missaoFormulario"
							element={
								<RouteAuth>
									<MissaoFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<MissaoFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<MissaoFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/logs"
							element={
								<RouteAuth>
									<Logs />
								</RouteAuth>
							}
						/>
						<Route
							path="/log/:id"
							element={
								<RouteAuth>
									<Log />
								</RouteAuth>
							}
						/>
						<Route
							path="/baterias"
							element={
								<RouteAuth>
									<Baterias />
								</RouteAuth>
							}
						/>
						<Route
							path="/bateriaFormulario"
							element={
								<RouteAuth>
									<BateriaFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<BateriaFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<BateriaFormulario />
									</RouteAuth>
								}
							/>
						</Route>

						<Route
							path="/relatorios"
							element={
								<RouteAuth>
									<Relatorios />
								</RouteAuth>
							}
						/>
						<Route
							path="/relatorioFormulario"
							element={
								<RouteAuth>
									<RelatorioFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<RelatorioFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<RelatorioFormulario />
									</RouteAuth>
								}
							/>
						</Route>

						<Route
							path="/configuracoesFormulario"
							element={
								<RouteAuth>
									<ConfiguracoesFormulario />
								</RouteAuth>
							}
						/>
						<Route
							path="/configuracoesFormulario/:id/:nome"
							element={
								<RouteAuth>
									<ConfiguracoesFormulario />
								</RouteAuth>
							}
						/>
						<Route
							path="/configuracoesEmpresaFormulario/:id/:nome"
							element={
								<RouteAuth>
									<ConfiguracoesEmpresaFormulario />
								</RouteAuth>
							}
						/>
						<Route
							path="/homepointSOSFormulario"
							element={
								<RouteAuth>
									<HomepointSOSFormulario />
								</RouteAuth>
							}
						/>
						<Route
							path="/cadastros"
							element={
								<RouteAuth>
									<Cadastros />
								</RouteAuth>
							}
						/>
						<Route
							path="/cadastrosEmpresas"
							element={
								<RouteAuth>
									<CadastrosEmpresas />
								</RouteAuth>
							}
						/>
						<Route
							path="/cadastroEmpresaFormulario"
							element={
								<RouteAuth>
									<CadastroEmpresaFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<CadastroEmpresaFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<CadastroEmpresaFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/cadastrosClientes"
							element={
								<RouteAuth>
									<CadastrosClientes />
								</RouteAuth>
							}
						/>
						<Route
							path="/cadastroClienteFormulario"
							element={
								<RouteAuth>
									<CadastroClienteFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<CadastroClienteFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<CadastroClienteFormulario />
									</RouteAuth>
								}
							/>
						</Route>
						<Route
							path="/cadastrosAdministradores"
							element={
								<RouteAuth>
									<CadastrosAdministradores />
								</RouteAuth>
							}
						/>
						<Route
							path="/cadastroAdministradorFormulario"
							element={
								<RouteAuth>
									<CadastroAdministradorFormulario />
								</RouteAuth>
							}
						>
							<Route
								index
								element={
									<RouteAuth>
										<CadastroAdministradorFormulario />
									</RouteAuth>
								}
							/>
							<Route
								path=":id"
								element={
									<RouteAuth>
										<CadastroAdministradorFormulario />
									</RouteAuth>
								}
							/>
						</Route>
					</Routes>
				</ToastProvider>
			</AuthProvider>
		</BrowserRouter>
	);
}

export default App;

import { useEffect, useRef } from "react";
import videojs from "video.js";
import "video.js/dist/video-js.css";

export const VideoJS = ({ options, onReady, ...rest }: any) => {
	const videoRef = useRef<any>(null);
	const playerRef = useRef<any>(null);

	useEffect(() => {
		if (!playerRef.current) {
			const videoElement = document.createElement("video-js");
			videoElement.classList.add("vjs-big-play-centered");
			videoRef.current.appendChild(videoElement);

			const player = (playerRef.current = videojs(videoElement, options, () => {
				onReady && onReady(player);
			}));
		} else {
			const player = playerRef.current;
			player.autoplay(options.autoplay);
			player.src(options.sources);
		}
		// eslint-disable-next-line
	}, [options, videoRef]);

	useEffect(() => {
		const player = playerRef.current;

		return () => {
			if (player && !player.isDisposed()) {
				player.dispose();
				playerRef.current = null;
			}
		};
		// eslint-disable-next-line
	}, [playerRef]);

	return (
		<div data-vjs-player {...rest}>
			<div ref={videoRef} {...rest} />
		</div>
	);
};

export default VideoJS;

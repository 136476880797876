import { useState } from "react";
import { useNavigate } from "react-router-dom";
import { Button, Modal, Spinner } from "react-bootstrap";
import moment from "moment";
import Icon from "@mdi/react";
import { mdiChevronRight, mdiTrashCanOutline } from "@mdi/js";

import { MissaoModel } from "../../models/MissaoModel";

import ListCard from "../ListCard";
import { formatDistance } from "../../config/defines";
import { useAuth } from "../../context/AuthContext";

type MissaoCardPropsContent = {
	data: MissaoModel;
	homepointData?: any;
	skeleton?: never;
};

type MissaoCardPropsSkeleton = {
	data?: never;
	homepointData?: never;
	skeleton: true;
};

type MissaoCardProps = MissaoCardPropsContent | MissaoCardPropsSkeleton;

export default function MissaoCard(props: MissaoCardProps) {
	const navigate = useNavigate();
	const { user } = useAuth();

	const [removeModal, setRemoveModal] = useState(false);
	const [removeLoadingModal, setRemoveLoadingModal] = useState(false);
	const [removed, setRemoved] = useState(false);

	function handleClick() {
		if (!props.skeleton && props.data) {
			navigate("/missaoFormulario/" + props.data.id);
		}
	}

	function handleClickRemove(e: any) {
		e.stopPropagation();
		setRemoveModal(true);
	}

	function handleCloseModal() {
		if (!removeLoadingModal) {
			setRemoveModal(false);
		}
	}

	async function handleRemove() {
		if (!props.skeleton && props.data) {
			setRemoveLoadingModal(true);
			setRemoved(true);
			setRemoveModal(false);
			setRemoveLoadingModal(false);
		}
	}

	let skeletonButton = "";
	if (props.skeleton) {
		skeletonButton = "skeleton skeleton-button";
	}

	if (removed) {
		return <></>;
	}

	let distancia;
	let tempo;
	if (props.data && props.homepointData?.homepoint && user?.parametros) {
		distancia = Number(props.data?.computeTotalDistance(props.homepointData.homepoint.latitude, props.homepointData.homepoint.longitude, user.parametros.alturaMinima));
		tempo = distancia / user.parametros.velocidade + (distancia > 0 ? 60 : 0);
	}

	return (
		<>
			<ListCard onClick={handleClick}>
				<ListCard.Content>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Nome</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.nome ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Data</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.dataString ?? "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Distância</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>
							{distancia ? (
								<>
									{formatDistance(distancia).value.toFixed(1)} <span className="text-muted small">{formatDistance(distancia).label}</span>
								</>
							) : (
								"..."
							)}
						</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Tempo de vôo</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{tempo ? moment.utc(Number(tempo) * 1000).format("HH:mm:ss") : "..."}</ListCard.Text>
					</ListCard.Item>
					<ListCard.Item>
						<ListCard.Title skeleton={props.skeleton}>Status</ListCard.Title>
						<ListCard.Text skeleton={props.skeleton}>{props.data?.ativo !== undefined ? (props.data?.ativo === 1 ? "Ativo" : "Inativo") : "..."}</ListCard.Text>
					</ListCard.Item>
				</ListCard.Content>
				<ListCard.Action>
					<Button variant="light" className={`me-2 ${skeletonButton}`} onClick={handleClickRemove}>
						<Icon path={mdiTrashCanOutline} size={1} className="text-danger" />
					</Button>
					<Icon path={mdiChevronRight} size={1} className={`text-muted m-2 ${skeletonButton}`} />
				</ListCard.Action>
			</ListCard>

			<Modal show={removeModal} onHide={handleCloseModal} centered>
				<Modal.Header closeButton>
					<Modal.Title>Remover Missão</Modal.Title>
				</Modal.Header>
				<Modal.Body className="text-center">{!removeLoadingModal ? "Deseja realmente remover esta missão?" : <Spinner />}</Modal.Body>
				{!removeLoadingModal && (
					<Modal.Footer>
						<Button variant="secondary" onClick={handleCloseModal}>
							Cancelar
						</Button>
						<Button variant="danger" onClick={handleRemove}>
							Remover
						</Button>
					</Modal.Footer>
				)}
			</Modal>
		</>
	);
}
